.main_container {
    height: 230px;
    margin: 0px 0px 20px 0px;
    border-radius: 20px;
    cursor: pointer;
    text-align: center;
}

.tree_container {
    border-radius: 20px;
    height: 80%;
    width: 90%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.main_container img {
    width: 120px;
    height: 180px;
}

.main_container p {
    font-size: 14px;
    color: gray;
    font-weight: bold;
}

.main_container Button {
    border: none;
    border-radius: 70%;
}
.buttons button {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #5a5188 !important;
  border-radius: 10px;
  color: #aba8b7;
  padding: 7px 15px;
  margin: 10px 1px 8px 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
}

.buttons button:hover {
  background-color: #5a5188;
  border: 1px solid #5a5188 !important;
  color: white;
}

.buttons .selected {
  background-color: var(--primary) !important;
  border: 1px solid var(--primary) !important;
  color: white !important;
}

/* 
.buttons button:active {
    background-color: var(--primary);
    border: none;
    color: white;
} */

.btn-color button {
  border: none;
  cursor: pointer;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 20px;
  color: gray;
  padding: 15px;
  margin: 10px 1px 8px 8px;
  text-align: center;
  justify-content: center;
  align-items: center;
}

/* .btn-color button:active {
    background-color: var(--primary);
    border: none;
    color: white;
} */

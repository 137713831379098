.fade-in {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out {
  opacity: 0;
  transform: translate(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-left {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out-right {
  opacity: 0;
  transform: translate(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-right {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out-left {
  opacity: 0;
  transform: translate(-30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.container-down {
  opacity: 0;
  transform: translateY(30px);
}

.fade-in-up {
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out-down {
  opacity: 0;
  transform: translateY(30px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.fadeSubmit-in {
  transition: opacity 0.2s ease, transform 0.5s ease;
}

.fadeSubmit-out {
  opacity: 0;
  transform: scale(1, 0);
  transition: opacity 0.2s ease, transform 0.5s ease;
}

.phoneInput {
  transition: 0.2s ease;
}

.dotparagraph {
  width: 8px;
  height: 8px;
  background-color: #ccc;
  border-radius: 50%;
  display: inline-block;
  margin: 0 5px;
  cursor: pointer;
}

.activedot {
  background-color: #558b2f;
}
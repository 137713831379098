.main_con {
  max-height: 260px;
  cursor: pointer;
  text-align: center;
  justify-content: center;
  /* display: flex; */
  border-radius: 20px;
}

.tree_con {
  height: 100%;
  width: 100%;
  position: absolute;
  bottom: 0;
  z-index: -1;
}

.donation_image {
  width: 120px;
  height: 180px;
}

.main_con p {
  font-size: 14px;
  color: gray;
  font-weight: bold;
}

.tree_det {
  display: flex;
  justify-content: center;
}

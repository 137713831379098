.category .icon {
    cursor: pointer;
}

.title {
    font-size: 19px;
}

.fade-in {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out {
    opacity: 0;
    transform: translate(30px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-left {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

/* .fade-out-right {
    opacity: 0;
    transform: translate(30px);
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-in-right {
    transition: opacity 0.5s ease, transform 0.5s ease;
}

.fade-out-left {
    opacity: 0;
    transform: translate(-30px);
    transition: opacity 0.5s ease, transform 0.5s ease;
} */
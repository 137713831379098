:root {
  --gradient: linear-gradient(to right,
      rgb(255, 64, 62) 0%,
      rgb(255, 129, 27) 100%);
  --gradient-sec: linear-gradient(to right,
      rgb(57, 26, 166) 0%,
      rgb(6, 163, 245) 100%);
  /* Primary */
  --primary: #558b2f;
  --primary-hover: #2f5515;
  --primary-dark: #305417;
  --secondary: #090915;
  --theme-light: #504387;
  --theme: #2c254a;
  /* Primary RGBA */
  --rgba-primary-1: rgba(85, 139, 47, 0.1);
  --rgba-primary-2: rgba(85, 139, 47, 0.2);
  --rgba-primary-3: rgba(85, 139, 47, 0.3);
  --rgba-primary-4: rgba(85, 139, 47, 0.4);
  --rgba-primary-5: rgba(85, 139, 47, 0.5);
  --rgba-primary-05: rgba(85, 139, 47, 0.05);
  --rgba-primary-6: rgba(85, 139, 47, 0.6);
  --rgba-primary-7: rgba(85, 139, 47, 0.7);
  --rgba-primary-8: rgba(85, 139, 47, 0.8);
  --rgba-primary-9: rgba(85, 139, 47, 0.9);
  --body-color: #84675a;
  --border-color: #cfdbed;
  --body-bg: #ffffff;
  --font-family-base: Poppins, sans-serif;
  --grey: #f4f6fa;
  --bg-image: "";
  --input-bg: #fff;
  --card-bg: #fff;
  --border-radius-base: 6px;
  --font-family-title: Poppins, sans-serif;
  --title: #fff;

  /* Other */
  /* --body-img: url(../images/background/bglight.png); */
  --headings-font-weight: 600;
  --headings-font-weight2: 400;
  --headings-font-weight3: 300;
}

hr:not([size]) {
  height: 1px;
}

h5,
.h5 {
  font-size: 1.25rem;
}

h6,
.h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small,
.small {
  font-size: 0.875em;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #eb8153;
  text-decoration: underline;
}

a:hover {
  color: #bc6742;
}

a:not([href]):not([class]),
a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr
    /* rtl:ignore */
  ;
  unicode-bidi: bidi-override;
}

.widget-title {
  margin-bottom: 25px;
  padding-bottom: 15px;
  position: relative;
}

@media only screen and (max-width: 1199px) {
  .widget-title {
    margin-bottom: 20px;
  }
}

.widget-title:after,
.widget-title:before {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  background: var(--primary);
}

@media only screen and (max-width: 1480px) {

  .widget-title:after,
  .widget-title:before {
    height: 4px;
  }
}

.widget-title:after {
  left: 0;
  width: 55px;
  border-radius: 0.6rem;
}

.widget-title:before {
  display: none;
  left: 60px;
  width: 7px;
}

.widget-title .title {
  position: relative;
  margin-bottom: 0px;
}

@media only screen and (max-width: 1199px) {
  .widget-title .title {
    font-size: 21px;
  }
}

@media only screen and (max-width: 767px) {
  .widget-title .title {
    font-size: 18px;
  }
}


.search-bx button {
  height: 100%;
  width: 60px;
  font-size: 24px;
  padding: 0;
  justify-content: center;
  border-radius: 0 0.6rem 0.6rem 0;
}

.search-bx button i {
  font-size: 12px;
}

.download-file {
  background-image: var(--gradient);
  color: #ffffff;
  padding: 25px 25px 20px 20px;
  border-radius: 4px;
}

.download-file .title {
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.download-file ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.download-file ul li a {
  background-color: #ffffff;
  color: #000;
  display: block;
  margin-bottom: 10px;
  border-radius: 4px;
  width: 100%;
  padding: 15px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.download-file ul li a i {
  width: 18px;
  height: 18px;
  position: relative;
}

.download-file ul li a i:after,
.download-file ul li a i:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  left: 0;
  top: 0;
}

.download-file ul li a i:before {
  -webkit-animation: download1 2s ease infinite;
  animation: download1 2s ease infinite;
}

.download-file ul li a .text {
  display: inline;
  position: relative;
}

.download-file ul li a:hover .text {
  color: var(--primary);
}

@-webkit-keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

@keyframes download1 {

  0%,
  10%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  60% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
  }
}

.widget_contact {
  position: relative;
  padding: 50px 45px 45px;
  background-size: cover;
  text-align: center;
  position: relative;
  z-index: 1;
  background-color: var(--primary);
  overflow: hidden;
}

.widget_contact:before {
  content: "";
  position: absolute;
  width: 45%;
  height: 65%;
  bottom: 0;
  right: 0;
  z-index: -1;
  /* background-image: url(../images/background/bg-pricetable-2.png); */
  background-size: cover;
  background-repeat: no-repeat;
}

.widget_contact .icon-bx i {
  font-size: 100px;
  color: var(--theme-text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 100px;
  margin-bottom: 15px;
}

.widget_contact img {
  margin-bottom: 20px;
}

.widget_contact h4,
.widget_contact .h4,
.widget_contact .comment-reply-title,
.widget_contact .wp-block-search .wp-block-search__label,
.wp-block-search .widget_contact .wp-block-search__label {
  font-size: 20px;
  font-weight: 600;
  color: var(--theme-text-color);
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  margin-bottom: 10px;
}

.widget_contact .phone-number {
  position: relative;
  font-size: 28px;
  color: var(--theme-text-color);
  font-weight: 600;
  margin-bottom: 5px;
}

.widget_contact .phone-number a {
  color: var(--theme-text-color);
}

.widget_contact .email {
  position: relative;
  color: var(--theme-text-color);
  font-weight: 600;
  margin-bottom: 30px;
}

.widget_contact .email a {
  color: var(--theme-text-color);
}

.service-carouse .item {
  overflow: hidden;
  border-radius: 6px;
}

.list-2 {
  display: table;
}

.list-2 li {
  width: 50% !important;
  float: left;
}

@media only screen and (max-width: 1200px) {
  .list-2 li {
    width: 100% !important;
  }
}

@media only screen and (max-width: 991px) {
  .list-2 li {
    width: 50% !important;
  }
}

@media only screen and (max-width: 575px) {
  .list-2 li {
    width: 100% !important;
  }
}

/* widget listing*/
.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list,
.widget_categories ul,
.widget_archive ul,
.widget_meta ul,
.widget_pages ul,
.widget_recent_comments ul,
.widget_nav_menu ul,
.widget_recent_entries ul,
.widget_services ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.wp-block-latest-posts li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.widget_categories ul li,
.widget_archive ul li,
.widget_meta ul li,
.widget_pages ul li,
.widget_recent_comments ul li,
.widget_nav_menu ul li,
.widget_recent_entries ul li,
.widget_services ul li {
  padding-bottom: 1.125rem;
  margin-bottom: 0.8125rem;
  position: relative;
  padding: 0.5rem 0rem 0.5rem 0.9375rem;
  margin-bottom: 0;
  line-height: 1.25rem;
}

.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories ul li a,
.widget_archive ul li a,
.widget_meta ul li a,
.widget_pages ul li a,
.widget_recent_comments ul li a,
.widget_nav_menu ul li a,
.widget_recent_entries ul li a,
.widget_services ul li a {
  color: inherit;
  text-transform: capitalize;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.wp-block-latest-posts li a:before,
.wp-block-categories-list li a:before,
.wp-block-archives-list li a:before,
.widget_categories ul li a:before,
.widget_archive ul li a:before,
.widget_meta ul li a:before,
.widget_pages ul li a:before,
.widget_recent_comments ul li a:before,
.widget_nav_menu ul li a:before,
.widget_recent_entries ul li a:before,
.widget_services ul li a:before {
  content: "\f0da";
  font-family: "Font Awesome 5 Free";
  width: auto;
  height: auto;
  font-size: 14px;
  font-weight: 700;
  left: 0;
  top: 8px;
  color: var(--primary);
  position: absolute;
  background: transparent;
}

.wp-block-latest-posts li a:hover,
.wp-block-categories-list li a:hover,
.wp-block-archives-list li a:hover,
.widget_categories ul li a:hover,
.widget_archive ul li a:hover,
.widget_meta ul li a:hover,
.widget_pages ul li a:hover,
.widget_recent_comments ul li a:hover,
.widget_nav_menu ul li a:hover,
.widget_recent_entries ul li a:hover,
.widget_services ul li a:hover {
  color: var(--primary);
}

.wp-block-latest-posts li li,
.wp-block-categories-list li li,
.wp-block-archives-list li li,
.widget_categories ul li li,
.widget_archive ul li li,
.widget_meta ul li li,
.widget_pages ul li li,
.widget_recent_comments ul li li,
.widget_nav_menu ul li li,
.widget_recent_entries ul li li,
.widget_services ul li li {
  border-bottom: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  line-height: 1.375rem;
}

.wp-block-latest-posts li li:last-child,
.wp-block-categories-list li li:last-child,
.wp-block-archives-list li li:last-child,
.widget_categories ul li li:last-child,
.widget_archive ul li li:last-child,
.widget_meta ul li li:last-child,
.widget_pages ul li li:last-child,
.widget_recent_comments ul li li:last-child,
.widget_nav_menu ul li li:last-child,
.widget_recent_entries ul li li:last-child,
.widget_services ul li li:last-child {
  border-bottom: 0;
  padding-bottom: 0;
}

.wp-block-latest-posts li li:before,
.wp-block-categories-list li li:before,
.wp-block-archives-list li li:before,
.widget_categories ul li li:before,
.widget_archive ul li li:before,
.widget_meta ul li li:before,
.widget_pages ul li li:before,
.widget_recent_comments ul li li:before,
.widget_nav_menu ul li li:before,
.widget_recent_entries ul li li:before,
.widget_services ul li li:before {
  top: 0.5rem;
  left: 0;
}

.wp-block-latest-posts ul,
.wp-block-categories-list ul,
.wp-block-archives-list ul,
.widget_categories ul ul,
.widget_archive ul ul,
.widget_meta ul ul,
.widget_pages ul ul,
.widget_recent_comments ul ul,
.widget_nav_menu ul ul,
.widget_recent_entries ul ul,
.widget_services ul ul {
  padding-left: 1.125rem;
  margin-top: 0.5rem;
  margin-left: -0.9375rem;
}

/*widget categories*/
.widget_archive li ul,
.wp-block-categories-list li ul,
.wp-block-archives-list li ul,
.wp-block-latest-posts li ul,
.widget_categories li ul {
  margin-bottom: -0.625rem;
}

.widget_archive ul li,
.wp-block-categories-list li,
.wp-block-archives-list li,
.wp-block-latest-posts li,
.widget_categories ul li {
  text-align: right;
  display: table;
  width: 100%;
  padding: 0.5rem 0rem 0.5rem 1.2rem !important;
  line-height: 1.3 !important;
}

.wp-block-categories-list li:before,
.wp-block-archives-list li:before,
.wp-block-latest-posts li:before {
  content: none !important;
}

.widget_archive li a,
.wp-block-latest-posts li a,
.wp-block-categories-list li a,
.wp-block-archives-list li a,
.widget_categories li a {
  float: left;
  text-transform: capitalize;
  text-align: left;
}

.wp-block-latest-posts,
.wp-block-categories-list,
.wp-block-archives-list {
  padding: 0 !important;
}

.widget_recent_comments ul {
  margin-top: -0.625rem;
}

.widget_recent_comments ul li {
  padding-left: 1.875rem;
  line-height: 1.625rem;
}

.widget_recent_comments ul li a:hover {
  color: inherit;
}

.widget_recent_comments ul li a:before {
  content: none;
}

.widget_recent_comments ul li:before {
  content: "\f27a";
  font-family: "Font Awesome 5 Free";
  background: transparent;
  width: auto;
  height: auto;
  position: absolute;
  left: 0;
  top: 0.625rem;
  font-size: 1.125rem;
  font-weight: 500;
}

.widget_meta ul li a abbr[title] {
  color: #333;
  border-bottom: none;
}

.widget_calendar th,
.widget_calendar td {
  text-align: center;
  border: 0.0625rem solid var(--rgba-primary-1);
  padding: 0.4em;
}

.widget_calendar th,
.widget_calendar tfoot td {
  border-color: rgba(0, 0, 0, 0.05);
}

.widget_calendar th:last-child,
.widget_calendar tfoot td:last-child {
  border: 0;
}

.widget_calendar tr {
  border-bottom: 0.0625rem solid var(--rgba-primary-1);
}

.widget_calendar tr:nth-child(2n + 2) {
  background-color: var(--rgba-primary-05);
}

.widget_calendar table {
  border-collapse: collapse;
  margin: 0;
  width: 100%;
}

.widget_calendar .calendar_wrap th,
.widget_calendar tfoot td a {
  color: #fff;
  background: var(--primary);
}

.widget_calendar .calendar_wrap td a {
  font-weight: 600;
  text-transform: uppercase;
  color: inherit;
}

.widget_calendar caption {
  text-align: center;
  font-weight: 600;
  caption-side: top;
  background: var(--primary);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  text-transform: uppercase;
  font-size: 0.9375rem;
}

.wp-block-calendar table th {
  font-weight: 400;
  background: var(--primary);
  color: #fff;
}

.wp-block-calendar table caption {
  color: #fff;
}

footer .widget_calendar th,
footer .widget_calendar td {
  border-color: rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr {
  border-bottom: 0.0625rem solid rgba(255, 255, 255, 0.1);
}

footer .widget_calendar tr:nth-child(2n + 2) {
  background-color: rgba(255, 255, 255, 0.05);
}

.wp-calendar-nav {
  background: var(--primary);
  color: #fff;
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.wp-calendar-nav span {
  padding: 0.3125rem 1.25rem;
  width: 44%;
  border-right: 0.0625rem solid rgba(255, 255, 255, 0.2);
}

.wp-calendar-nav span a {
  color: #fff;
}

.widget_archive select {
  width: 100%;
  padding: 0.3125rem;
  border: 0.0625rem solid #ccc;
}

.list-2-column ul {
  display: table;
}

.list-2-column ul li {
  width: 50%;
  float: left;
}

.widget_categories select,
.widget_archive select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}

.widget_text select {
  width: 100%;
  border: 0.0625rem solid #ccc;
}

.widget_text select option {
  width: 100%;
}

.widget_text p {
  font-size: 0.9375rem;
  line-height: 1.75rem;
}

/*widget rss  */
.rsswidget img {
  display: inherit;
}

.widget-title .rsswidget {
  display: inline-block;
}

ul a.rsswidget,
ul cite {
  font-weight: 600;
  color: var(--title);
}

ul a.rsswidget {
  font-size: 1.125rem;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

ul a.rsswidget:hover {
  color: var(--primary);
}

.rssSummary {
  margin: 1.25rem 0;
  line-height: 1.7;
  font-size: 15px;
}

.rss-date {
  display: block;
  margin-top: 0.3125rem;
  font-weight: 400;
}

.widget_rss ul li {
  margin-bottom: 1.25rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 0;
}

.widget_rss ul li:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  padding-bottom: 0;
}

.widget_gallery ul {
  padding-left: 0;
  display: flex;
  margin-left: -0.3rem;
  margin-right: -0.3rem;
}

.widget_gallery.gallery-grid-2 li {
  width: 50%;
}

.widget_gallery.gallery-grid-4 li {
  width: 25%;
}

.widget_gallery li {
  display: inline-block;
  width: 33.33%;
  float: left;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  padding: 5px;
}

.widget_gallery li img {
  display: inline-block;
  width: 100%;
}

.widget_gallery li:nth-child(3n-3) {
  margin-right: 0;
}

.widget_gallery li:hover {
  opacity: 0.7;
}

.widget_gallery li a {
  display: inline-block;
}

.widget_tag_cloud .tagcloud {
  clear: both;
  display: table;
}

.widget_tag_cloud .tagcloud a {
  position: relative;
  padding: 8px 15px;
  display: inline-block;
  margin: 0 10px 10px 0;
  font-size: 14px;
  color: var(--primary);
  line-height: 1.4;
  border: 0;
  background: var(--rgba-primary-1);
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 1480px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 18px;
    margin: 0 10px 10px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .widget_tag_cloud .tagcloud a {
    padding: 6px 14px;
    margin: 0 8px 8px 0;
  }
}

.widget_tag_cloud .tagcloud a:hover {
  box-shadow: 0px 5px 12px var(--rgba-primary-4);
  background-color: var(--primary-hover);
  border-color: var(--primary-hover);
  color: #fff;
}

.widget_tag_cloud .tagcloud a span {
  display: inline-block;
}

.recent-posts-entry .widget-post-bx .widget-post {
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  margin-bottom: 20px;
  transition: all 0.5s;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-media {
  width: 92px;
  min-width: 92px;
  margin-right: 15px;
  overflow: hidden;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 1199px) {
  .recent-posts-entry .widget-post-bx .widget-post .dz-media {
    width: 75px;
    min-width: 75px;
  }
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta {
  margin-bottom: 0;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul {
  line-height: 1;
}

.recent-posts-entry .widget-post-bx .widget-post .dz-meta ul li {
  font-size: 15px;
}

.recent-posts-entry .widget-post-bx .widget-post .title {
  line-height: 1.3;
  font-family: "Poppins";
  margin-bottom: 8px;
  display: block;
  font-weight: 600;
  font-size: 16px;
}

.recent-posts-entry .widget-post-bx .widget-post .post-date {
  font-size: 14px;
}

.recent-posts-entry .widget-post-bx .widget-post:not(:last-child) {
  margin-bottom: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.service_menu_nav {
  background: #222222;
  position: relative;
  padding: 40px 45px;
  z-index: 1;
  box-shadow: 0 0 60px 0 rgba(0, 0, 0, 0.1);
}

@media only screen and (max-width: 767px) {
  .service_menu_nav {
    padding: 40px;
  }
}

.service_menu_nav:after,
.service_menu_nav:before {
  content: "";
  position: absolute;
  height: 50%;
  z-index: -1;
  bottom: 0;
  right: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  background-size: cover;
  background-repeat: no-repeat;
}

.service_menu_nav:after {
  /* background-image: url(../images/background/bg-pricetable-1.png); */
  width: 17%;
}

.service_menu_nav:before {
  /* background-image: url(../images/background/bg-pricetable-2.png); */
  width: 40%;
  opacity: 0.2;
}

.service_menu_nav ul li {
  padding: 0;
}

.service_menu_nav ul li a {
  display: block;
  position: relative;
  padding: 15px 0;
  margin-bottom: 10px;
  color: #fff;
  font-size: 18px;
  z-index: 1;
  font-weight: 500;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

@media only screen and (max-width: 767px) {
  .service_menu_nav ul li a {
    padding: 12px 0;
    margin-bottom: 5px;
    font-size: 16px;
  }
}

.service_menu_nav ul li a:before,
.service_menu_nav ul li a:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  z-index: -1;
  background-color: var(--primary);
  opacity: 0;
  transform: translateX(15px);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.service_menu_nav ul li a:after {
  width: 100%;
  width: calc(100% + 70px);
  right: 0px;
}

@media only screen and (max-width: 767px) {
  .service_menu_nav ul li a:after {
    width: calc(100% + 50px);
  }
}

.service_menu_nav ul li a:before {
  width: 7px;
  right: -15px;
}

.service_menu_nav ul li:hover a,
.service_menu_nav ul li.current-menu-item a,
.service_menu_nav ul li.active a {
  color: var(--theme-text-color);
}

.service_menu_nav ul li:hover a:after,
.service_menu_nav ul li:hover a:before,
.service_menu_nav ul li.current-menu-item a:after,
.service_menu_nav ul li.current-menu-item a:before,
.service_menu_nav ul li.active a:after,
.service_menu_nav ul li.active a:before {
  transform: translateX(0px);
  opacity: 1;
}

.service_menu_nav svg {
  position: absolute;
  bottom: -23px;
  left: -20px;
  transform: rotateY(180deg);
  height: 50px;
  width: 175px;
}

.wp-block-categories-dropdown,
.wp-block-archives-dropdown {
  margin-bottom: 20px;
}

.wp-block-categories-dropdown select:after,
.wp-block-archives-dropdown select:after {
  content: "\f078";
  font-weight: 900;
  font-family: "Line Awesome Free";
  position: absolute;
  right: 50px;
  left: auto;
  top: 50%;
}

.post-tags strong {
  font-weight: 700;
  color: #212529;
  margin-right: 10px;
}

.post-tags a {
  margin-right: 10px;
  display: inline-block;
}

.widget_profile .dz-media {
  width: 100%;
  min-width: 100%;
}

.widget_profile .dz-media img {
  border-radius: var(--border-radius-base);
}

.widget_profile p {
  font-size: 16px;
}

.widget-newslatter .form-control,
.widget-newslatter .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .widget-newslatter select,
.widget-newslatter .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .widget-newslatter select,
.widget-newslatter .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .widget-newslatter input,
.widget-newslatter .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .widget-newslatter textarea {
  background-color: #f3f4f6;
  border-radius: var(--border-radius);
  border: 0;
}

.widget-newslatter p {
  font-size: 16px;
}

.widget-newslatter button {
  width: 60px;
  height: 60px;
  padding: 0;
  justify-content: center;
}

.widget-newslatter .input-group {
  margin-bottom: 0;
}

.shop-widget,
.widget.style-1 {
  box-shadow: 0 0 60px 20px rgba(0, 0, 0, 0.1);
  padding: 35px;
  border-bottom: 4px solid;
  border-color: var(--primary);
}

.shop-widget .search-bx,
.widget.style-1 .search-bx {
  margin-bottom: 0;
}

.icon-xs {
  display: inline-block;
  text-align: center;
  width: 30px;
  line-height: 1;
}

.icon-xs i {
  vertical-align: middle;
  font-size: 20px;
}

.icon-bx-wraper {
  position: relative;
}

.icon-bx-wraper .dz-tilte {
  margin-top: 0;
  font-weight: 600;
}

.icon-bx-wraper .after-titile-line {
  margin-bottom: 10px;
}

.icon-bx-wraper p:last-child {
  margin: 0;
}

.icon-bx-wraper.center {
  text-align: center;
}

.icon-bx-wraper.left .icon-bx-xl,
.icon-bx-wraper.left .icon-bx-lg,
.icon-bx-wraper.left .icon-bx-md,
.icon-bx-wraper.left .icon-bx-sm,
.icon-bx-wraper.left .icon-bx-xs {
  float: left;
  margin-right: 15px;
}

.icon-bx-wraper.left .icon-xl,
.icon-bx-wraper.left .icon-lg,
.icon-bx-wraper.left .icon-md,
.icon-bx-wraper.left .icon-sm,
.icon-bx-wraper.left .icon-xs {
  line-height: 1;
  float: left;
  margin-right: 10px;
}

.icon-bx-wraper.right {
  text-align: right;
}

.icon-bx-wraper.right .icon-bx-xl,
.icon-bx-wraper.right .icon-bx-lg,
.icon-bx-wraper.right .icon-bx-md,
.icon-bx-wraper.right .icon-bx-sm,
.icon-bx-wraper.right .icon-bx-xs {
  float: right;
  margin-left: 20px;
}

.icon-bx-wraper.right .icon-xl,
.icon-bx-wraper.right .icon-lg,
.icon-bx-wraper.right .icon-md,
.icon-bx-wraper.right .icon-sm,
.icon-bx-wraper.right .icon-xs {
  line-height: 1;
  float: right;
  margin-left: 10px;
}

.icon-bx-wraper.bx-style-1,
.icon-bx-wraper.bx-style-2 {
  border-width: 1px;
  border-style: solid;
  border-color: #ebedf2;
}

.icon-bx-wraper.bx-style-2.center [class*="icon-bx-"] {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xl {
  margin-left: -75px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-lg {
  margin-left: -60px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-md {
  margin-left: -50px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-sm {
  margin-left: -40px;
}

.icon-bx-wraper.bx-style-2.center .icon-bx-xs {
  margin-left: -20px;
}

.icon-bx-wraper.bx-style-2.left [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  left: 0;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.icon-bx-wraper.bx-style-2.right [class*="icon-bx-"] {
  position: absolute;
  top: auto;
  right: 0;
  -webkit-transform: translateX(50%);
  -ms-transform: translateX(50%);
  transform: translateX(50%);
}

.icon-content {
  overflow: hidden;
}

[class*="icon-bx-"][class*="bg-"] a,
[class*="icon-bx-"][class*="bg-"] span {
  color: #ffffff;
}

[class*="icon-bx-"].bg-white a {
  color: inherit;
}

[class*="icon-bx-"][class*="border-"] {
  display: table;
  line-height: normal;
  margin-left: auto;
  margin-right: auto;
}

[class*="icon-bx-"][class*="border-"] .icon-cell {
  display: table-cell;
  vertical-align: middle;
}

.dz-box,
.dz-info,
.dz-tilte,
.dz-tilte-inner {
  position: relative;
}

.dz-tilte-inner {
  display: inline-block;
}

.dz-box[class*="border-"],
.dz-info[class*="border-"] {
  border-color: #eee;
}

.dz-info.border-1,
.dz-info.border-2,
.dz-info.border-3,
.dz-info.border-4,
.dz-info.border-5 {
  border-top: none;
}

.border-1,
.border-2,
.border-3,
.border-4,
.border-5 {
  border-style: solid;
}

.border-1 {
  border-width: 1px;
}

.border-2 {
  border-width: 2px;
}

.border-3 {
  border-width: 3px;
}

.border-4 {
  border-width: 4px;
}

.border-5 {
  border-width: 5px;
}

.left-border,
.right-border {
  position: relative;
}

.left-border:before,
.right-border:before {
  content: "";
  position: absolute;
  top: 5px;
  width: 1px;
  height: 90%;
  background: #ccc;
}

.right-border:before {
  right: 0;
}

.dz-media,
.dz-post-media {
  position: relative;
  overflow: hidden;
}

.dz-media img,
.dz-post-media img {
  width: 100%;
  height: auto;
}

.dz-box-bg {
  overflow: hidden;
  background-size: cover;
}

.dz-box-bg .btn {
  overflow: unset;
}

.dz-box-bg .glyph-icon {
  font-size: 50px;
}

.dz-box-bg .icon-bx-wraper {
  background-color: #f8f8f8;
}

.dz-box-bg .text-primary,
.dz-box-bg .icon-content .dz-tilte,
.dz-box-bg .icon-content p,
.dz-box-bg .icon-box-btn .btn {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-box-bg.active .icon-bx-wraper {
  background-color: rgba(0, 0, 0, 0.7);
}

.dz-box-bg.active .text-primary,
.dz-box-bg.active .icon-content .dz-tilte,
.dz-box-bg.active .icon-content p,
.dz-box-bg.active .icon-box-btn .btn {
  color: #fff;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-box-bg .icon-box-btn .btn {
  background-color: #eeeeee;
  padding: 15px 20px;
  text-transform: uppercase;
  font-size: 15px;
  border-radius: 0;
  color: #1abc9c;
  font-weight: 600;
  margin-bottom: 0;
}

.icon-bx-wraper.style-1 {
  padding: 40px 45px;
  position: relative;
  border-radius: var(--border-radius-base);
}

.icon-bx-wraper.style-1 .icon-content .text {
  padding-top: 10px;
}

.icon-bx-wraper.style-1 .icon-media {
  line-height: 1.2;
}

.icon-bx-wraper.style-1 .icon-media i {
  color: var(--primary);
  margin-bottom: 10px;
  font-size: 70px;
}

.icon-bx-wraper.style-1 .hover-border:after {
  content: "";
  position: absolute;
  top: -5px;
  left: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid var(--primary);
  border-top: 5px solid var(--primary);
  border-radius: var(--border-radius-base);
  opacity: 0;
  transition: 0.5s;
}

.icon-bx-wraper.style-1 .hover-border:before {
  content: "";
  position: absolute;
  top: -5px;
  right: -5px;
  width: 0;
  height: 0;
  border-right: 5px solid var(--primary);
  border-top: 5px solid var(--primary);
  border-radius: var(--border-radius-base);
  opacity: 0;
  transition: 0.5s;
}

.icon-bx-wraper.style-1 .hover-border-2:after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: -5px;
  width: 0;
  height: 0;
  border-left: 5px solid var(--primary);
  border-bottom: 5px solid var(--primary);
  border-radius: var(--border-radius-base);
  opacity: 0;
  transition: 0.5s;
}

.icon-bx-wraper.style-1 .hover-border-2:before {
  content: "";
  position: absolute;
  bottom: -5px;
  right: -5px;
  width: 0;
  height: 0;
  border-right: 5px solid var(--primary);
  border-bottom: 5px solid var(--primary);
  border-radius: var(--border-radius-base);
  opacity: 0;
  transition: 0.5s;
}

.icon-bx-wraper.style-1.active .hover-border:after,
.icon-bx-wraper.style-1.active .hover-border:before,
.icon-bx-wraper.style-1.active .hover-border-2:after,
.icon-bx-wraper.style-1.active .hover-border-2:before {
  width: 115px;
  height: 115px;
  opacity: 1;
}

.icon-bx-wraper.style-1:hover .hover-border:after,
.icon-bx-wraper.style-1:hover .hover-border:before,
.icon-bx-wraper.style-1:hover .hover-border-2:after,
.icon-bx-wraper.style-1:hover .hover-border-2:before {
  width: 115px;
  height: 115px;
  opacity: 1;
}

.icon-bx-wraper.style-2 {
  background: #fff;
  padding: 20px 33px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  border-radius: var(--border-radius-base);
}

.icon-bx-wraper.style-2 .icon-media {
  line-height: 1.2;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.icon-bx-wraper.style-2 .icon-media i {
  color: var(--primary);
  font-size: 60px;
  line-height: 1;
}

.icon-bx-wraper.style-2 .icon-content {
  margin-top: 5px;
}

.icon-bx-wraper.style-2 .icon-content .title {
  font-weight: 500;
  margin-bottom: 0;
}

.icon-bx-wraper.style-2:hover {
  transform: translateY(-10px);
}

.icon-bx-wraper.style-2:hover .icon-media {
  animation: shake 0.5s infinite;
}

.blog-swiper {
  margin: -15px;
  width: auto;
}

.blog-swiper .swiper-slide>div {
  margin: 15px;
  height: 100%;
}

.dz-card {
  position: relative;
}

.dz-card .dz-info {
  padding: 30px;
  position: relative;
}

.dz-card .dz-title {
  margin-bottom: 15px;
  word-break: break-word;
}

@media only screen and (max-width: 575px) {
  .dz-card .dz-title {
    margin-bottom: 15px;
  }
}

.dz-card.blog-half {
  display: flex;
  margin-bottom: 30px;
}

.dz-card.blog-half .dz-info {
  padding: 30px;
  position: relative;
  flex: 1;
}

.dz-card.blog-half .dz-media {
  margin-bottom: 0;
  max-width: 300px;
  min-width: 300px;
}

.dz-card.blog-half .dz-media img {
  height: 100%;
  object-fit: cover;
  min-height: 235px;
}

@media only screen and (max-width: 575px) {
  .dz-card.blog-half .dz-media img {
    min-height: auto;
  }
}

.dz-card.blog-half.post-video .post-video-icon {
  width: 60px;
  height: 60px;
  font-size: 20px;
}

.dz-card.blog-half .swiper-container {
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .dz-card.blog-half {
    display: block;
  }

  .dz-card.blog-half .dz-media {
    max-width: 100%;
    min-width: 100%;
  }
}

.dz-media.height-sm {
  height: 220px;
}

.dz-media.height-sm img {
  height: 100%;
  object-fit: cover;
}

.dz-media.height-md {
  height: 265px;
}

.dz-media.height-md img {
  height: 100%;
  object-fit: cover;
}

@media only screen and (max-width: 1199px) {
  .dz-media.height-md {
    height: 220px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-media.height-md {
    height: 200px;
  }
}

.dz-media.height-lg {
  height: 350px;
}

@media only screen and (max-width: 1199px) {
  .dz-media.height-lg {
    height: 300px;
  }
}

@media only screen and (max-width: 767px) {
  .dz-media.height-lg {
    height: 220px;
  }

  .dz-media.height-lg img {
    height: 100%;
    object-fit: cover;
  }
}

.dz-meta {
  margin-bottom: 10px;
}

.dz-meta ul {
  margin: 0;
  padding: 0;
}

.dz-meta ul li {
  display: inline-block;
  color: #777;
  margin-right: 20px;
}

.dz-meta ul li:last-child {
  margin-right: 0;
}

.dz-meta ul li i {
  margin-right: 5px;
  color: var(--primary);
}

.dz-meta ul li a {
  color: #777;
}

.dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: 3px;
  padding: 6px 15px;
  font-size: 15px;
}

.dz-badge:hover {
  color: white;
}

.post-video .post-video-icon {
  width: 100px;
  height: 100px;
  line-height: 100px;
  border-radius: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  color: var(--primary);
  font-size: 30px;
  margin: 0 auto;
  background-color: #ffffff;
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
  box-shadow: 0 0 0 8px rgba(255, 255, 255, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 5px;
}

@media only screen and (max-width: 575px) {
  .post-video .post-video-icon {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 14px;
  }
}

.post-video .post-video-icon:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 50%;
  -khtml-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 100%;
  animation: animationSignal1;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal1;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.post-video .post-video-icon:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid #fff;
  -webkit-border-radius: 100%;
  -khtml-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  animation: animationSignal2;
  animation-iteration-count: infinite;
  animation-duration: 3s;
  -webkit-animation: animationSignal2;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 3s;
  z-index: -1;
  transform: scale(1);
  -moz-transform: scale(1);
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  -moz-transition: all 0.5;
  -o-transition: all 0.5;
  -webkit-transition: all 0.5;
  -ms-transition: all 0.5;
  transition: all 0.5;
}

.dz-card.style-1 .dz-media {
  border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
}

@media only screen and (max-width: 767px) {
  .dz-card.style-1 .dz-media {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
  }
}

.dz-card.style-1 .dz-media .date a {
  color: #fff;
  padding: 6px 14px;
  border-radius: 3px;
  background: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 12px;
  left: 12px;
}

.dz-card.style-1 .dz-media::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.dz-card.style-1:hover .dz-media::before {
  left: 100%;
  right: 0;
}

.dz-card.style-1 .dz-info {
  padding: 20px 30px 35px 30px;
  border-radius: 0 0 9px 9px;
}

.dz-card.style-1 .dz-info.shadow {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

@media only screen and (max-width: 575px) {
  .dz-card.style-1 .dz-info {
    padding: 25px;
  }
}

.dz-card.style-1 .dz-title {
  font-weight: 600;
  margin-bottom: 5px;
  line-height: 1.2;
}

.dz-card.style-1 p {
  font-weight: 400;
  margin-bottom: 22px;
}

.dz-card.style-1 .dz-meta ul li i {
  font-size: 13px;
  color: var(--body-bg);
  top: 0;
}

.dz-card.style-1.blog-half .dz-info {
  border-radius: 0px 6px 6px 0px;
}

@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half .dz-info {
    border-radius: 0 0 6px 6px;
  }
}

.dz-card.style-1.blog-half .dz-media {
  border-radius: var(--border-radius-base) 0 0 var(--border-radius-base);
}

@media only screen and (max-width: 767px) {
  .dz-card.style-1.blog-half .dz-media {
    border-radius: var(--border-radius-base) var(--border-radius-base) 0 0;
  }
}

.swiper-container .dz-card.style-1 {
  margin: 15px;
}

.pricing-wrapper .pricing-inner {
  position: relative;
  position: relative;
}

.pricing-wrapper .pricing-inner img {
  border-radius: 6px 6px 0 0;
  width: 100%;
}

.pricing-wrapper .pricing-inner .table-price {
  position: absolute;
  bottom: 20px;
  left: 0;
  color: #fff;
  width: 100%;
  display: flex;
  z-index: 9;
  padding: 10px 25px;
  align-items: center;
  justify-content: space-between;
}

@media only screen and (max-width: 1280px) {
  .pricing-wrapper .pricing-inner .table-price {
    padding: 10px 15px;
  }
}

.pricing-wrapper .pricing-inner .table-price .price {
  font-weight: 500;
  font-size: 25px;
  color: #fff;
}

.pricing-wrapper .pricing-inner:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(to bottom,
      rgba(59, 111, 245, 0) 42%,
      var(--primary) 100%);
  opacity: 0.9;
}

.pricing-wrapper .pricing-price {
  padding: 30px;
  border-radius: 0 0 6px 6px;
  box-shadow: 0px 20px 50px rgba(0, 0, 0, 0.2);
  background: #fff;
}

@media only screen and (max-width: 767px) {
  .pricing-wrapper .pricing-price {
    padding: 20px;
  }
}

.pricing-wrapper .pricing-price .pricing-features li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  font-size: 16px;
  font-weight: 500;
  color: #222;
}

.pricing-wrapper .pricing-price .pricing-features li span {
  color: var(--primary);
}

.pricing-wrapper .pricing-price .pricing-features li span img {
  width: 35px;
  margin-right: 10px;
}

.dz-img-overlay1,
.dz-img-overlay2,
.dz-img-overlay3,
.dz-img-overlay4,
.dz-img-overlay5,
.dz-img-overlay6,
.dz-img-overlay7,
.dz-img-overlay8,
.dz-img-overlay9,
.dz-img-overlay11,
.dz-img-overlay12 {
  position: relative;
}

.dz-img-overlay1:before,
.dz-img-overlay1:after,
.dz-img-overlay2:before,
.dz-img-overlay2:after,
.dz-img-overlay3:before,
.dz-img-overlay3:after,
.dz-img-overlay4:before,
.dz-img-overlay4:after,
.dz-img-overlay5:before,
.dz-img-overlay5:after,
.dz-img-overlay6:before,
.dz-img-overlay6:after,
.dz-img-overlay7:before,
.dz-img-overlay7:after,
.dz-img-overlay8:before,
.dz-img-overlay8:after,
.dz-img-overlay9:before,
.dz-img-overlay9:after,
.dz-img-overlay11:before,
.dz-img-overlay11:after,
.dz-img-overlay12:before,
.dz-img-overlay12:after {
  content: "";
  background: #282d32;
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0.4;
  z-index: 1;
  -webkit-transition: all 0.8s linear;
  -moz-transition: all 0.8s linear;
  -ms-transition: all 0.8s linear;
  -o-transition: all 0.8s linear;
  transition: all 0.8s linear;
}

.dz-img-overlay1.overlay-primary,
.dz-img-overlay2.overlay-primary,
.dz-img-overlay3.overlay-primary,
.dz-img-overlay4.overlay-primary,
.dz-img-overlay5.overlay-primary,
.dz-img-overlay6.overlay-primary,
.dz-img-overlay7.overlay-primary,
.dz-img-overlay8.overlay-primary,
.dz-img-overlay9.overlay-primary,
.dz-img-overlay11.overlay-primary,
.dz-img-overlay12.overlay-primary {
  background: var(--primary);
}

.dz-box:hover .dz-img-overlay1:before {
  opacity: 0.7;
}

.dz-box:hover .dz-img-overlay2:before {
  opacity: 0.9;
}

.dz-box:hover .dz-img-overlay3:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-box:hover .dz-img-overlay4:before {
  width: 100%;
  left: 0;
}

.dz-box:hover .dz-img-overlay5:before {
  height: 100%;
  top: 0;
}

.dz-box:hover .dz-img-overlay6:before {
  height: 100%;
}

.dz-box:hover .dz-img-overlay7:before,
.dz-box:hover .dz-img-overlay7:after {
  width: 50%;
}

.dz-box:hover .dz-img-overlay8:before,
.dz-box:hover .dz-img-overlay8:after {
  width: 100%;
  opacity: 0.3;
}

.dz-box:hover .dz-img-overlay9:before,
.dz-box:hover .dz-img-overlay9:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.dz-img-overlay1:before {
  width: 100%;
  height: 100%;
  opacity: 0;
}

.dz-img-overlay1:hover:before {
  opacity: 0.5;
}

.dz-img-overlay2:before {
  width: 100%;
  height: 100%;
  opacity: 0;
  background: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%);
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.4) 100%);
}

.dz-img-overlay2:before:hover:before {
  opacity: 0.9;
}

.dz-img-overlay3:before {
  left: 50%;
  top: 50%;
}

.dz-img-overlay3:hover:before {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.dz-img-overlay4:before {
  left: 50%;
  top: 0;
  height: 100%;
}

.dz-img-overlay4:hover:before {
  width: 100%;
  left: 0;
}

.dz-img-overlay5:before {
  left: 0;
  top: 50%;
  width: 100%;
}

.dz-img-overlay5:hover:before {
  height: 100%;
  top: 0;
}

.dz-img-overlay6:before {
  left: 0;
  top: auto;
  bottom: 0;
  width: 100%;
}

.dz-img-overlay6:hover:before {
  height: 100%;
}

.dz-img-overlay7:before,
.dz-img-overlay7:after {
  height: 100%;
}

.dz-img-overlay7:after {
  left: auto;
  right: 0;
}

.dz-img-overlay7:hover:before,
.dz-img-overlay7:hover:after {
  width: 50%;
}

.dz-img-overlay8:before,
.dz-img-overlay8:after {
  height: 100%;
}

.dz-img-overlay8:after {
  left: auto;
  right: 0;
}

.dz-img-overlay8:hover:before,
.dz-img-overlay8:hover:after {
  width: 100%;
  opacity: 0.3;
}

.dz-img-overlay9:before:before,
.dz-img-overlay9:before:after,
.dz-img-overlay9:after:before,
.dz-img-overlay9:after:after {
  left: auto;
  right: 0;
  top: auto;
  bottom: 0;
}

.dz-img-overlay9:before:hover:before,
.dz-img-overlay9:before:hover:after,
.dz-img-overlay9:after:hover:before,
.dz-img-overlay9:after:hover:after {
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

/*  */
.overlay-black-light,
.overlay-black-middle,
.overlay-black-dark,
.overlay-gradient-light,
.overlay-gradient-middle,
.overlay-gradient-dark,
.overlay-white-light,
.overlay-white-middle,
.overlay-white-dark,
.overlay-primary-light,
.overlay-primary-middle,
.overlay-primary-dark,
.overlay-primary {
  position: relative;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before,
.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before,
.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before,
.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.overlay-black-light .container,
.overlay-black-light .container-fluid,
.overlay-black-light .container-sm,
.overlay-black-light .container-md,
.overlay-black-light .container-lg,
.overlay-black-light .container-xl,
.overlay-black-middle .container,
.overlay-black-middle .container-fluid,
.overlay-black-middle .container-sm,
.overlay-black-middle .container-md,
.overlay-black-middle .container-lg,
.overlay-black-middle .container-xl,
.overlay-black-dark .container,
.overlay-black-dark .container-fluid,
.overlay-black-dark .container-sm,
.overlay-black-dark .container-md,
.overlay-black-dark .container-lg,
.overlay-black-dark .container-xl,
.overlay-gradient-light .container,
.overlay-gradient-light .container-fluid,
.overlay-gradient-light .container-sm,
.overlay-gradient-light .container-md,
.overlay-gradient-light .container-lg,
.overlay-gradient-light .container-xl,
.overlay-gradient-middle .container,
.overlay-gradient-middle .container-fluid,
.overlay-gradient-middle .container-sm,
.overlay-gradient-middle .container-md,
.overlay-gradient-middle .container-lg,
.overlay-gradient-middle .container-xl,
.overlay-gradient-dark .container,
.overlay-gradient-dark .container-fluid,
.overlay-gradient-dark .container-sm,
.overlay-gradient-dark .container-md,
.overlay-gradient-dark .container-lg,
.overlay-gradient-dark .container-xl,
.overlay-white-light .container,
.overlay-white-light .container-fluid,
.overlay-white-light .container-sm,
.overlay-white-light .container-md,
.overlay-white-light .container-lg,
.overlay-white-light .container-xl,
.overlay-white-middle .container,
.overlay-white-middle .container-fluid,
.overlay-white-middle .container-sm,
.overlay-white-middle .container-md,
.overlay-white-middle .container-lg,
.overlay-white-middle .container-xl,
.overlay-white-dark .container,
.overlay-white-dark .container-fluid,
.overlay-white-dark .container-sm,
.overlay-white-dark .container-md,
.overlay-white-dark .container-lg,
.overlay-white-dark .container-xl,
.overlay-primary-light .container,
.overlay-primary-light .container-fluid,
.overlay-primary-light .container-sm,
.overlay-primary-light .container-md,
.overlay-primary-light .container-lg,
.overlay-primary-light .container-xl,
.overlay-primary-middle .container,
.overlay-primary-middle .container-fluid,
.overlay-primary-middle .container-sm,
.overlay-primary-middle .container-md,
.overlay-primary-middle .container-lg,
.overlay-primary-middle .container-xl,
.overlay-primary-dark .container,
.overlay-primary-dark .container-fluid,
.overlay-primary-dark .container-sm,
.overlay-primary-dark .container-md,
.overlay-primary-dark .container-lg,
.overlay-primary-dark .container-xl,
.overlay-primary .container,
.overlay-primary .container-fluid,
.overlay-primary .container-sm,
.overlay-primary .container-md,
.overlay-primary .container-lg,
.overlay-primary .container-xl {
  position: relative;
  z-index: 1;
}

.overlay-black-light:before,
.overlay-black-middle:before,
.overlay-black-dark:before {
  background: #2c254a;
}

.overlay-gradient-light:before,
.overlay-gradient-middle:before,
.overlay-gradient-dark:before {
  background: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%);
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.65) 100%);
}

.overlay-white-light:before,
.overlay-white-middle:before,
.overlay-white-dark:before {
  background: #fff;
}

.overlay-primary-light:before,
.overlay-primary-middle:before,
.overlay-primary-dark:before,
.overlay-primary:before {
  background: var(--primary);
}

.overlay-primary-light:before,
.overlay-white-light:before,
.overlay-gradient-light:before,
.overlay-black-light:before {
  opacity: 0.3;
}

.overlay-primary-middle:before,
.overlay-white-middle:before,
.overlay-gradient-middle:before,
.overlay-black-middle:before {
  opacity: 0.7;
}

.overlay-primary-dark:before,
.overlay-white-dark:before,
.overlay-gradient-dark:before,
.overlay-black-dark:before {
  opacity: 0.9;
}

.overlay-bx {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.overlay-bx .align-b {
  height: auto;
  left: 0;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  width: 100%;
  transform: translate(0);
  -o-transform: translate(0);
  -moz-transform: translate(0);
  -webkit-transform: translate(0);
}

.overlay-bx .align-m {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon {
  height: auto;
  left: 50%;
  list-style: outside none none;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  width: 100%;
  padding: 0 10px;
}

.overlay-icon a {
  display: inline-block;
  padding: 0;
  margin: 0 2px;
}

.overlay-icon a i {
  background-color: #fff;
}

.overlay-bx:hover a>i,
.dz-media:hover .overlay-bx a>i,
.dz-box-bx:hover .overlay-bx a>i {
  opacity: 1;
  visibility: visible;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.overlay-bx:hover,
.dz-media:hover .overlay-bx,
.dz-box:hover .overlay-bx,
.ow-img:hover .overlay-bx,
.ow-portfolio-img:hover .overlay-bx {
  opacity: 1;
  visibility: visible;
}

.gallery-box-1 .overlay-bx .overlay-icon span i,
.gallery-box-1 .overlay-bx .overlay-icon a i {
  background-color: #000;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  cursor: pointer;
}

.gallery-box-1 .overlay-bx .icon-bx-xs {
  width: 60px;
  height: 60px;
  line-height: 60px;
}

.img-overlay-gradients {
  position: relative;
}

.img-overlay-gradients:before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
}

.img-overlay-gradients.gradients-secondary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, #090915 100%);
  background: -webkit-linear-gradient(bottom, transparent 0%, #090915 100%);
  background: linear-gradient(to bottom, transparent 0%, #090915 100%);
}

.img-overlay-gradients.gradients-primary:before {
  background: -moz-linear-gradient(bottom, transparent 0%, var(--primary) 100%);
  background: -webkit-linear-gradient(bottom,
      transparent 0%,
      var(--primary) 100%);
  background: linear-gradient(to bottom, transparent 0%, var(--primary) 100%);
}

.overlay-shine .dz-media {
  position: relative;
  overflow: hidden;
  border-radius: var(--border-radius-base);
}

.overlay-shine .dz-media:before {
  position: absolute;
  top: 0;
  left: -85%;
  z-index: 2;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
}

.overlay-shine .dz-media img {
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.overlay-shine:hover .dz-media:before {
  -webkit-animation: shine 0.75s;
  animation: shine 0.75s;
}

@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}

@keyframes shine {
  100% {
    left: 125%;
  }
}

p {
  font-size: 15px;
}

/* .page-content {
  background-color: #fff;
} */

.main-bnr {
  height: 900px;
  position: relative;
  /* background-image: url(../images/background/bg.jpg); */
  display: flex;
  align-items: center;
  overflow: hidden;
  padding-top: 50px;
  padding-bottom: 30px;
  z-index: 1;
  background: var(--primary);
  background-blend-mode: luminosity;
}

.main-bnr:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 340px;
  height: 100%;
  /* background-image: url(../images/banner/bnr4.png); */
  opacity: 0.25;
  z-index: 0;
}

@media only screen and (max-width: 991px) {
  .main-bnr {
    height: unset;
    padding-top: 135px;
  }
}

.main-bnr .container,
.main-bnr .container-fluid,
.main-bnr .container-sm,
.main-bnr .container-md,
.main-bnr .container-lg,
.main-bnr .container-xl {
  position: relative;
  z-index: 3;
}

.main-bnr h1,
.main-bnr .h1 {
  font-size: 60px;
  color: #fff;
  font-weight: 600;
  line-height: 1.2;
  margin-bottom: 20px;
  position: relative;
}

.main-bnr h1 .text-line,
.main-bnr .h1 .text-line {
  position: relative;
}

.main-bnr h1 .text-line:after,
.main-bnr .h1 .text-line:after {
  content: "";
  top: 75px;
  z-index: -1;
  right: 5px;
  height: 34px;
  width: 380px;
  position: absolute;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: left top;
  /* background-image: url(../images/home-banner/shape2.svg); */
}

.main-bnr .text {
  font-size: 18px;
  font-weight: 400;
  margin-bottom: 40px;
  line-height: 1.7;
  max-width: 575px;
}

@media only screen and (max-width: 1191px) {
  .main-bnr .text {
    font-size: 18px;
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr .text {
    font-size: 15px;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr .text {
    width: 100%;
  }
}

.main-bnr .shape1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 250px;
  height: auto;
}

@media only screen and (max-width: 1480px) {
  .main-bnr .shape1 {
    width: 200px;
  }
}

@media only screen and (max-width: 1191px) {
  .main-bnr .shape1 {
    width: 150px;
  }
}

@media only screen and (max-width: 575px) {
  .main-bnr .shape1 {
    width: 100px;
  }
}

.main-bnr .shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 150px;
  height: auto;
}

@media only screen and (max-width: 1480px) {
  .main-bnr .shape2 {
    width: 120px;
  }
}

@media only screen and (max-width: 1191px) {
  .main-bnr .shape2 {
    width: 100px;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr .shape2 {
    width: 80px;
  }
}

.main-bnr .shape3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  width: 93px;
  height: auto;
}

@media only screen and (max-width: 767px) {
  .main-bnr .shape3 {
    width: 35px;
  }
}

@media only screen and (max-width: 1280px) {
  .main-bnr {
    height: 700px;
  }

  .main-bnr h1,
  .main-bnr .h1 {
    font-size: 50px;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr {
    height: 820px;
  }

  .main-bnr h1,
  .main-bnr .h1 {
    font-size: 45px;
  }
}

@media only screen and (max-width: 991px) {
  .main-bnr {
    height: unset;
  }

  .main-bnr h1,
  .main-bnr .h1 {
    margin-bottom: 5px;
  }

  .main-bnr h1 .text-line:after,
  .main-bnr .h1 .text-line:after {
    top: 37px;
    right: 0px;
    width: 190px;
  }

  .main-bnr .video-btn {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .main-bnr {
    height: auto;
    padding-top: 135px;
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 575px) {
  .main-bnr {
    padding-top: 100px;
  }

  .main-bnr h1,
  .main-bnr .h1 {
    font-size: 34px;
  }

  .main-bnr h1 .text-line:after,
  .main-bnr .h1 .text-line:after {
    top: 32px;
    width: 170px;
  }
}

.caart-box .caart-box-1 {
  padding: 0px 30px;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }

  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }

  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }

  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }

  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }

  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }

  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }

  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }

  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }

  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

/* @keyframes upToDown{
	49% {
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
	}
	50% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
	}
	51% {
		opacity: 1;
	}
} */
.main-bnr .dz-shape li {
  position: absolute;
}

.main-bnr .dz-shape li:nth-child(1) {
  top: 40%;
  left: 8%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .main-bnr .dz-shape li:nth-child(1) {
    left: 2%;
  }
}

.main-bnr .dz-shape li:nth-child(2) {
  top: 26%;
  left: 26%;
  animation: aniRotate 10s infinite linear;
}

.main-bnr .dz-shape li:nth-child(3) {
  bottom: 15%;
  left: 35%;
  animation: aniRotate 10s infinite linear;
}

.main-bnr .dz-shape li:nth-child(4) {
  top: 35%;
  right: 10%;
  animation: dZwobble 4s infinite;
}

.main-bnr .dz-shape li:nth-child(5) {
  bottom: 30%;
  right: 10%;
  animation: dZwobble 4s infinite;
}

.main-bnr .dz-shape li:nth-child(6) {
  bottom: 13%;
  right: 30%;
  animation: dZwobble 4s infinite;
}

@media only screen and (max-width: 1280px) {
  .main-bnr .dz-shape {
    display: none;
  }
}

.dz-bnr-inr .dz-shape li {
  position: absolute;
}

.dz-bnr-inr .dz-shape li:nth-child(1) {
  top: 40%;
  left: 8%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .dz-bnr-inr .dz-shape li:nth-child(1) {
    left: 2%;
  }
}

.dz-bnr-inr .dz-shape li:nth-child(2) {
  bottom: 15%;
  left: 34%;
  top: unset;
  animation: aniRotate 10s infinite linear;
  height: 45px;
}

.dz-bnr-inr .dz-shape li:nth-child(3) {
  top: 31%;
  right: 26%;
  animation: aniRotate 10s infinite linear;
  height: 45px;
}

@media only screen and (max-width: 1280px) {
  .dz-bnr-inr .dz-shape {
    display: none;
  }
}

@keyframes aniRotate {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}

@keyframes dZwobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}

.dz-shape-2 li {
  position: absolute;
}

.dz-shape-2 li:nth-child(1) {
  top: 20%;
  left: 5%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .dz-shape-2 li:nth-child(1) {
    left: 3%;
  }
}

.dz-shape-2 li:nth-child(2) {
  bottom: 15%;
  left: 8%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .dz-shape-2 li:nth-child(2) {
    left: 3%;
  }
}

.dz-shape-2 li:nth-child(3) {
  top: 15%;
  right: 8%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .dz-shape-2 li:nth-child(3) {
    right: 3%;
  }
}

.dz-shape-2 li:nth-child(4) {
  bottom: 15%;
  right: 8%;
  animation: aniRotate 10s infinite linear;
}

@media only screen and (max-width: 1680px) {
  .dz-shape-2 li:nth-child(4) {
    right: 3%;
  }
}

@media only screen and (max-width: 1280px) {
  .dz-shape-2 {
    display: none;
  }
}

.dz-bnr-inr .shape1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 0;
  width: 200px;
  height: auto;
}

@media only screen and (max-width: 1480px) {
  .dz-bnr-inr .shape1 {
    width: 150px;
    height: auto;
  }
}

@media only screen and (max-width: 1191px) {
  .dz-bnr-inr .shape1 {
    width: 150px;
    height: auto;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .shape1 {
    width: 100px;
    height: auto;
  }
}

.dz-bnr-inr .shape2 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 0;
  width: 100px;
  height: auto;
}

@media only screen and (max-width: 1480px) {
  .dz-bnr-inr .shape2 {
    width: 120px;
  }
}

@media only screen and (max-width: 1191px) {
  .dz-bnr-inr .shape2 {
    width: 85px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .shape2 {
    width: 70px;
  }
}

.dz-bnr-inr .shape3 {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 99;
  height: auto;
  width: 100px;
}

@media only screen and (max-width: 1480px) {
  .dz-bnr-inr .shape3 {
    width: 65px;
  }
}

@media only screen and (max-width: 575px) {
  .dz-bnr-inr .shape3 {
    width: 35px;
  }
}

.banner-media {
  position: relative;
  width: 610px;
  min-width: 610px;
  -webkit-animation: aniBnrMedia 5s infinite;
  animation: aniBnrMedia 5s infinite;
}

@media only screen and (max-width: 1280px) {
  .banner-media {
    max-width: 450px;
    min-width: 450px;
    width: 100%;
  }
}

@media only screen and (max-width: 991px) {
  .banner-media {
    max-width: unset !important;
    min-width: unset !important;
  }
}

.banner-media .media {
  width: 100%;
  min-width: 100%;
}

.banner-media .shadow-box {
  width: 250px;
  height: 250px;
  background: var(--primary) !important;
  position: absolute;
  top: 34%;
  left: 29%;
  border-radius: 50%;
  box-shadow: 0px 0px 250px 25px var(--rgba-primary-2);
  z-index: -1;
}

@media only screen and (max-width: 1280px) {
  .banner-media .shadow-box {
    width: 200px;
    height: 200px;
  }
}

@media only screen and (max-width: 575px) {
  .banner-media .shadow-box {
    width: 150px;
    height: 150px;
  }
}

.banner-media [class*="shape"] {
  position: absolute;
}

.banner-media .shape1 {
  width: 90px;
  top: 40px;
  right: 15px;
  left: auto;
}

@media only screen and (max-width: 1380px) {
  .banner-media .shape1 {
    width: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-media .shape1 {
    top: 25px;
    width: 35px;
  }
}

.banner-media .shape2 {
  left: 210px;
  top: 0px;
  width: 80px;
  -webkit-animation: dZwobble 4s infinite;
  animation: dZwobble 4s infinite;
}

@media only screen and (max-width: 1380px) {
  .banner-media .shape2 {
    left: 200px;
    width: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-media .shape2 {
    left: 100px;
    width: 25px;
  }
}

.banner-media .shape3 {
  left: 0;
  top: 230px;
  width: 100px;
  -webkit-animation: dZwobble 5s infinite;
  animation: dZwobble 5s infinite;
}

@media only screen and (max-width: 1380px) {
  .banner-media .shape3 {
    width: 50px;
    left: 30px;
  }
}

@media only screen and (max-width: 991px) {
  .banner-media .shape3 {
    width: 25px;
    left: 30px;
    top: 120px;
  }
}

@-webkit-keyframes dZwobble {
  0% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }

  50% {
    -webkit-transform: rotate(1deg) translate(5px, 5px);
    transform: rotate(1deg) translate(5px, 5px);
  }

  100% {
    -webkit-transform: rotate(-1deg) translate(-5px, -5px);
    transform: rotate(-1deg) translate(-5px, -5px);
  }
}

@-webkit-keyframes aniBnrShape2 {
  100% {
    -webkit-transform: rotateY(360deg);
    transform: rotateY(360deg);
  }
}

@-webkit-keyframes aniBnrShape3 {
  100% {
    -webkit-transform: rotateX(-360deg);
    transform: rotateX(-360deg);
  }
}

@-webkit-keyframes aniBnrShape4 {
  0% {
    -webkit-transform: rotate(0) translate(2px, 2px) scale(1);
    transform: rotate(0) translate(2px, 2px) scale(1);
  }

  50% {
    -webkit-transform: rotate(50deg) translate(2px, 2px) scale(1.2);
    transform: rotate(50deg) translate(2px, 2px) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(0) translate(2px, 2px) scale(1);
    transform: rotate(0) translate(2px, 2px) scale(1);
  }
}

@-webkit-keyframes aniBnrShape5 {
  0% {
    -webkit-transform: skewY(0);
    transform: skewY(0);
  }

  50% {
    -webkit-transform: skewY(20deg);
    transform: skewY(20deg);
  }

  100% {
    -webkit-transform: skewY(0);
    transform: skewY(0);
  }
}

@-webkit-keyframes aniBnrShape7 {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-webkit-keyframes aniBnrMedia {
  0% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }

  50% {
    -webkit-transform: rotate(-1deg) translate(-2px, -2px);
    transform: rotate(-1deg) translate(-2px, -2px);
  }

  100% {
    -webkit-transform: rotate(1deg) translate(2px, 2px);
    transform: rotate(1deg) translate(2px, 2px);
  }
}

.list-style-1 {
  counter-reset: li;
}

.list-style-1 li {
  display: block;
  position: relative;
  font-size: 20px;
  font-weight: 500;
  color: #527d95;
  padding: 6px 10px 6px 50px;
}

@media only screen and (max-width: 767px) {
  .list-style-1 li {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .list-style-1 li {
    font-size: 16px;
    padding: 6px 10px 6px 45px;
  }
}

.list-style-1 li:after {
  content: counter(li);
  /* background-image: url(../images/shape/polygon7.svg); */
  height: 35px;
  width: 35px;
  position: absolute;
  left: 0;
  background-position: center -0.5px;
  counter-increment: li;
  text-align: center;
  line-height: 35px;
  color: #fff;
  font-size: 16px;
}

@media only screen and (max-width: 575px) {
  .list-style-1 li:after {
    top: 2px;
  }
}

.coins-wrapper .list-style-1 {
  margin-bottom: 50px;
}

@media only screen and (max-width: 575px) {
  .coins-wrapper .list-style-1 {
    margin-bottom: 30px;
  }
}

.coins-wrapper .sub-title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
  .coins-wrapper .sub-title {
    font-size: 20px;
  }
}

.coins-wrapper .flex-coin1,
.coins-wrapper .flex-coin2,
.coins-wrapper .flex-coin3,
.coins-wrapper .flex-coin4,
.coins-wrapper .flex-coin5 {
  position: absolute;
  z-index: -1;
}

.coins-wrapper .flex-coin1 img,
.coins-wrapper .flex-coin2 img,
.coins-wrapper .flex-coin3 img,
.coins-wrapper .flex-coin4 img,
.coins-wrapper .flex-coin5 img {
  width: 48px;
}

.coins-wrapper .flex-coin1:after,
.coins-wrapper .flex-coin2:after,
.coins-wrapper .flex-coin3:after,
.coins-wrapper .flex-coin4:after,
.coins-wrapper .flex-coin5:after {
  content: "";
  height: 136px;
  width: 45px;
  position: absolute;
  left: 2px;
  top: 5px;
  z-index: -1;
  border-radius: 30px 30px 0 0;
  opacity: 0.4;
}

.coins-wrapper .flex-coin1 {
  top: 200px;
  left: 5%;
}

.coins-wrapper .flex-coin1:after {
  background: linear-gradient(180deg, #85ea09 0%, rgba(133, 233, 9, 0) 100%);
}

.coins-wrapper .flex-coin2 {
  bottom: 250px;
  left: 10%;
}

.coins-wrapper .flex-coin2:after {
  background: linear-gradient(180deg, #f9a922 0%, rgba(250, 171, 35, 0) 100%);
}

@media only screen and (max-width: 1700px) {
  .coins-wrapper .flex-coin2 {
    left: 4%;
  }
}

.coins-wrapper .flex-coin3 {
  bottom: 160px;
  right: 12%;
}

.coins-wrapper .flex-coin3:after {
  background: linear-gradient(180deg, #33b994 0%, rgba(50, 183, 147, 0) 100%);
}

.coins-wrapper .flex-coin4 {
  top: 65px;
  right: 10%;
}

.coins-wrapper .flex-coin4:after {
  background: linear-gradient(180deg, #7895f1 0%, rgba(117, 146, 240, 0) 100%);
}

.coins-wrapper .flex-coin5 {
  top: 50%;
  right: 4%;
  transform: translateY(-50%);
}

.coins-wrapper .flex-coin5:after {
  background: linear-gradient(180deg, #14a7fa 0%, rgba(10, 125, 246, 0) 100%);
}

@media only screen and (max-width: 1700px) {
  .coins-wrapper .coin-wave {
    display: none;
  }
}

.coins-media-wrapper {
  position: relative;
  display: block;
  width: 537px;
  height: 529px;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (max-width: 1280px) {
  .coins-media-wrapper {
    width: 450px;
    height: 442px;
  }
}

@media only screen and (max-width: 991px) {
  .coins-media-wrapper {
    width: 537px;
    height: 529px;
  }
}

@media only screen and (max-width: 767px) {
  .coins-media-wrapper {
    width: 450px;
    height: 442px;
  }
}

@media only screen and (max-width: 575px) {
  .coins-media-wrapper {
    width: 320px;
    height: 315px;
  }
}

.coins-media-wrapper .main-circle1 {
  z-index: -1;
  height: 100%;
  width: 100%;
  position: relative;
}

.coins-media-wrapper .circle-box {
  /* background-image: url(../images/shape/polygon2.svg); */
  background-position: center;
  background-size: 98%;
  background-repeat: no-repeat;
  position: absolute;
  height: 100%;
  width: 100%;
}

.coins-media-wrapper .center-media {
  width: 190px;
  height: 190px;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.coins-media-wrapper .coin-list {
  height: 100%;
  width: 100%;
}

.coins-media-wrapper .coin-list li {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -29px;
  margin-top: -29px;
  width: 58px;
  height: 58px;
}

.coins-media-wrapper .coin-list li img {
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .coins-media-wrapper .coin-list li img {
    transform: scale(0.75);
  }
}

.contact-wrapper .form-wrapper-box {
  margin-bottom: -180px;
  margin-top: 0;
}

.contact-wrapper .section-head {
  margin-bottom: 40px;
}

.contact-wrapper .section-head .title {
  margin-bottom: 12px;
}

.contact-wrapper .section-head p {
  font-size: 18px;
  line-height: 1.6;
}

@media only screen and (max-width: 575px) {
  .contact-wrapper .section-head {
    margin-bottom: 30px;
  }

  .contact-wrapper .section-head p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 991px) {
  .contact-wrapper .form-wrapper-box {
    margin-bottom: 0;
  }
}

.form-wrapper-box {
  border-radius: 20px;
  box-shadow: 0px 25px 50px rgba(9, 9, 21, 0.1);
  padding: 50px;
  background-color: #fff;
}

@media only screen and (max-width: 1480px) {
  .form-wrapper-box {
    padding: 40px;
  }
}

@media only screen and (max-width: 575px) {
  .form-wrapper-box {
    padding: 30px;
  }
}

.form-wrapper-box .section-head {
  margin-bottom: 30px;
}

.form-wrapper-box .section-head .title {
  margin-bottom: 5px;
}

.form-wrapper-box .section-head p {
  font-weight: 400;
  font-size: 20px;
  color: #444444;
}

@media only screen and (max-width: 1480px) {
  .form-wrapper-box .section-head p {
    font-size: 18px;
  }
}

@media only screen and (max-width: 1280px) {
  .form-wrapper-box .section-head p {
    font-size: 16px;
  }
}

@media only screen and (max-width: 575px) {
  .form-wrapper-box .section-head {
    margin-bottom: 25px;
  }
}

.form-wrapper-box .form-control,
.form-wrapper-box .wp-block-categories-dropdown select,
.wp-block-categories-dropdown .form-wrapper-box select,
.form-wrapper-box .wp-block-archives-dropdown select,
.wp-block-archives-dropdown .form-wrapper-box select,
.form-wrapper-box .comment-respond .comment-form p.comment-form-author input,
.comment-respond .comment-form p.comment-form-author .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p.comment-form-email input,
.comment-respond .comment-form p.comment-form-email .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p.comment-form-url input,
.comment-respond .comment-form p.comment-form-url .form-wrapper-box input,
.form-wrapper-box .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .form-wrapper-box textarea {
  background-color: transparent;
  z-index: 0 !important;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.form-wrapper-box textarea.form-control,
.form-wrapper-box .comment-respond .comment-form p textarea,
.comment-respond .comment-form p .form-wrapper-box textarea {
  height: 150px;
  padding: 20px 20px;
}

.form-wrapper-box .input-group {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .form-wrapper-box .input-group {
    margin-bottom: 20px;
  }
}

.form-wrapper-box .input-area {
  position: relative;
}

.form-wrapper-box .input-area label {
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  top: 15px;
  left: 15px;
  color: #555555;
  -webkit-transition: all 0.2s;
  -ms-transition: all 0.2s;
  transition: all 0.2s;
  background-color: #fff;
  display: block;
  padding: 0 10px;
}

@media only screen and (max-width: 1280px) {
  .form-wrapper-box .input-area label {
    font-size: 14px;
  }
}

.form-wrapper-box .input-area.focused label {
  position: absolute;
  font-size: 13px;
  top: -8px;
  color: #eb8153;
  z-index: 2;
}

@media only screen and (max-width: 575px) {
  .form-wrapper-box .input-area label {
    top: 12px;
  }
}

.dz-media.style-1 {
  overflow: visible;
}

.dz-media.style-1 img {
  position: relative;
  margin-left: -30px;
}

@media only screen and (max-width: 1280px) {
  .dz-media.style-1 img {
    margin-left: 0px;
  }
}

.dz-media.style-1 .object-1 {
  position: absolute;
  width: 100px;
  top: 0;
  left: 40%;
  animation: aniBnrMedia 1s infinite;
}

@media only screen and (max-width: 575px) {
  .dz-media.style-1 .object-1 {
    width: 70px;
  }
}

.crypto-box.style-1 {
  background: #fff;
  padding: 23px 30px;
  border-radius: var(--border-radius-base);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2);
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
  position: relative;
  z-index: 9;
}

.crypto-box.style-1 .crypto-info h5,
.crypto-box.style-1 .crypto-info .h5 {
  font-weight: 600;
}

.crypto-box.style-1 .crypto-info .text {
  font-weight: 400;
  font-size: 13px;
}

.crypto-box.style-2 {
  background: #fff;
  padding: 20px 25px;
  border-radius: var(--border-radius-base);
  margin-top: 10px;
}

.crypto-box.style-2 .crypto-info {
  display: flex;
  margin-bottom: 5px;
  align-items: center;
}

.crypto-box.style-2 .crypto-info .title {
  font-weight: 600;
  margin-left: 10px;
}

.crypto-box.style-2 .crypto-info i {
  font-size: 40px;
  color: var(--primary);
  line-height: 1.2;
}

.crypto-box.style-2 .crypto-text .text {
  font-weight: 400;
  font-size: 15px;
  margin-bottom: 0;
}

.crypto-box.style-3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 17px;
  border-radius: 6px;
  transition: 0.5s;
}

.crypto-box.style-3 .crypto-info {
  margin-left: 15px;
}

.crypto-box.style-3 .crypto-info h6,
.crypto-box.style-3 .crypto-info .h6 {
  margin-bottom: 2px;
}

.crypto-box.style-3 .crypto-info .text {
  font-weight: 400;
  font-size: 14px;
  color: #84675a;
}

.crypto-box.style-3:hover {
  background: #fff;
}

.overlay-box.style-1 {
  position: relative;
  overflow: hidden;
  padding: 20px 30px;
  min-height: 280px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 575px) {
  .overlay-box.style-1 {
    min-height: 200px;
  }
}

.overlay-box.style-1 .contant-box {
  height: 100%;
}

.overlay-box.style-1 .contant-box .crypto-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.overlay-box.style-1 .contant-box .crypto-info h5,
.overlay-box.style-1 .contant-box .crypto-info .h5 {
  font-weight: 600;
  font-size: 28px;
  color: #f68e00;
  margin-bottom: 0;
}

.overlay-box.style-1 .contant-box .crypto-info h5.text-purple,
.overlay-box.style-1 .contant-box .crypto-info .text-purple.h5 {
  color: #6670ff;
}

.overlay-box.style-1 .contant-box .crypto-info .text {
  color: #553810;
  font-size: 14px;
  font-weight: 400;
}

.overlay-box.style-1 .contant-box .crypto-media {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(40px, 30px) rotate(16.9deg);
}

@media only screen and (max-width: 575px) {
  .overlay-box.style-1 .contant-box .crypto-media {
    width: 35%;
  }
}

.overlay-box.style-1 .contant-box .btn-link {
  position: absolute;
  bottom: 25px;
  left: 30px;
  color: #553810;
  font-size: 15px;
  font-weight: 400;
}

.overlay-box.style-2 {
  position: relative;
  padding: 20px 30px;
  min-height: 280px;
  border-radius: var(--border-radius-base);
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-box.style-2 .title {
  font-weight: 600;
  font-size: 45px;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 767px) {
  .overlay-box.style-2 .title {
    font-size: 38px;
  }
}

@media only screen and (max-width: 575px) {
  .overlay-box.style-2 .title {
    font-size: 32px;
  }
}

.overlay-box.style-2 .btn-link {
  position: absolute;
  bottom: 25px;
  left: 30px;
  font-weight: 400;
  font-size: 15px;
  color: #553810;
}

.overlay-box.style-2 .btn-link i {
  margin-left: 7px;
}

.overlay-box.style-2 .btn-link.lg {
  position: unset;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
}

.overlay-box.style-2 .btn-link.lg i {
  margin-left: 15px;
}

.bg-light-yellow {
  background: #fff7d7;
}

.bg-light-purple {
  background: #f1f2fe;
}

.overlay-style-1 {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-blend-mode: luminosity;
  background: var(--primary);
  background-position: center;
}

.overlay-style-1:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.3;
  z-index: -1;
}

.overlay-style-2 {
  position: relative;
  z-index: 1;
  background-size: cover;
  background-blend-mode: luminosity;
  background: var(--primary);
  background-position: center;
}

.overlay-style-2:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.69;
  z-index: -1;
}

.widget-box.style-1 {
  display: flex;
  padding: 10px;
  border-radius: 13px;
  align-items: center;
}

.widget-box.style-1 .dz-media {
  height: 90px;
  margin-right: 15px;
  min-width: 90px;
  position: relative;
}

.widget-box.style-1 .dz-media img {
  width: 100%;
  height: 100%;
  border-radius: var(--border-radius-base);
}

.widget-box.style-1 .dz-media::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  z-index: 2;
  display: block;
  width: 50%;
  height: 100%;
  background: -webkit-linear-gradient(left,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  background: linear-gradient(to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.3) 100%);
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.widget-box.style-1 .dz-info .date a {
  font-weight: 600;
  font-size: 12px;
  background: var(--primary);
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
}

.widget-box.style-1 .dz-info .title {
  font-weight: 600;
  color: #523325;
  margin-top: 10px;
  margin-bottom: 0;
}

.widget-box.style-1:hover .dz-media::before {
  left: 100%;
  right: 0;
}

.crypto-wrapper {
  margin-top: -62px;
}

@media only screen and (max-width: 1280px) {
  .crypto-wrapper {
    margin-top: -42px;
  }
}

.call-to-action .title {
  font-size: 55px;
}

@media only screen and (max-width: 1480px) {
  .call-to-action .title {
    font-size: 52px;
  }
}

@media only screen and (max-width: 1280px) {
  .call-to-action .title {
    font-size: 42px;
  }
}

@media only screen and (max-width: 575px) {
  .call-to-action .title {
    font-size: 24px;
  }
}

.clients-logo .logo-main {
  opacity: 0.2;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.clients-logo .logo-main:hover {
  opacity: 1;
}

.dz-meta .post-author img {
  border: 2px solid #fff;
  border-radius: 100%;
  width: 50px;
}

.info-box.style-1 {
  color: #fff !important;
  padding: 45px;
  background: var(--primary);
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 1280px) {
  .info-box.style-1 {
    padding: 30px;
  }
}

@media only screen and (max-width: 575px) {
  .info-box.style-1 {
    padding: 20px;
  }
}

.info-box .widget {
  margin-bottom: 30px !important;
}

.info-box .widget ul li {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  width: 80%;
  padding: 20px 0;
}

@media only screen and (max-width: 575px) {
  .info-box .widget ul li {
    padding: 10px 0;
    margin-bottom: 0;
  }
}

.info-box .widget ul li p {
  color: #fff;
  opacity: 0.6;
  margin-bottom: 5px;
}

.info-box .widget ul li h6,
.info-box .widget ul li .h6 {
  font-size: 16px;
  font-weight: 500;
}

.dz-social.style-1 .social-icon {
  display: flex;
  align-items: center;
  gap: 15px;
}

.dz-social.style-1 .social-icon li {
  background: rgba(255, 255, 255, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 8px;
  text-align: center;
  position: relative;
  z-index: 1;
}

.dz-social.style-1 .social-icon li a {
  color: #fff;
  text-align: center;
  color: #fff;
  text-align: center;
  line-height: 40px;
}

.dz-social.style-1 .social-icon li:after {
  content: "";
  position: absolute;
  height: 40px;
  width: 0;
  background-color: var(--secondary);
  left: 0px;
  top: 0;
  z-index: -1;
  border-radius: var(--border-radius-base);
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
  z-index: -1;
}

.dz-social.style-1 .social-icon li:hover:after {
  width: 100%;
}

.form-wrapper.style-1 {
  padding-left: 30px;
}

@media only screen and (max-width: 991px) {
  .form-wrapper.style-1 {
    padding-left: 0;
  }
}

.content-inner.style-1 {
  padding: 85px 0 0 0 !important;
}

.dz-meta {
  margin-bottom: 10px;
}

.dz-meta ul {
  margin: 0;
  padding: 0;
}

.dz-meta ul li {
  display: inline-block;
  position: relative;
  font-size: 15px;
  margin-right: 30px;
}

@media only screen and (max-width: 991px) {
  .dz-meta ul li {
    font-size: 13px;
  }
}

.dz-meta ul li:after {
  content: "";
  height: 6px;
  width: 6px;
  background-color: var(--body-bg);
  position: absolute;
  top: 50%;
  right: -20px;
  border-radius: 50%;
  transform: translateY(-50%);
}

@media only screen and (max-width: 575px) {
  .dz-meta ul li:after {
    height: 4px;
    width: 4px;
    right: -3px;
  }
}

.dz-meta ul li:last-child {
  margin-right: 0;
  padding-right: 0;
}

.dz-meta ul li:last-child:after {
  content: none;
}

.dz-meta ul li i {
  font-size: 16px;
  margin-right: 2px;
  color: var(--primary);
  transform: scale(1.3);
  position: relative;
  top: 2px;
  padding-right: 0.2rem;
}

.dz-meta ul li a {
  color: inherit;
}

@media only screen and (max-width: 575px) {
  .dz-meta ul li {
    margin-right: 15px;
    padding-right: 10px;
  }
}

button.btn.btn-primary.style-2.two {
  display: none;
  border-radius: 0 6px 6px 0 !important;
}

@media only screen and (max-width: 767px) {
  button.btn.btn-primary.style-2.two {
    display: unset !important;
  }
}

@media only screen and (max-width: 767px) {
  button.btn.btn-primary.style-2 {
    display: none;
  }
}

.contant-box.style-1 {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 575px) {
  .contant-box.style-1 {
    display: unset;
  }
}

.contant-box.style-2 {
  padding: 25px 14px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 1280px) {
  .contant-box.style-2 {
    padding: 20px 14px;
  }
}

@media only screen and (max-width: 575px) {
  .contant-box.style-2 {
    padding: 10px;
  }
}

.blog-single {
  margin-bottom: 3.125rem;
}

.blog-single .dz-post-meta {
  margin-bottom: 1.25rem;
}

.blog-single .dz-post-text {
  padding-bottom: 5px;
}

.blog-single .dz-post-text:after {
  content: "";
  display: block;
  width: 100%;
  clear: both;
}

.blog-single .dz-post-tags {
  margin-top: 1.25rem;
}

.blog-single .dz-post-media {
  border-radius: 0.25rem;
}

.blog-single.dz-card .post-tags {
  margin-bottom: 0;
}

.blog-single .dz-media img,
.blog-single .dz-post-media img {
  border-radius: var(--border-radius-base);
}

.blog-single .dz-title {
  font-size: 36px;
}

@media only screen and (max-width: 767px) {
  .blog-single .dz-title {
    font-size: 28px;
  }
}

@media only screen and (max-width: 575px) {
  .blog-single .dz-title {
    font-size: 24px;
  }
}

.blog-single.style-1 .dz-title {
  margin: 10px 0;
  font-weight: 700;
}

.blog-single.style-1 .btn-link {
  color: black;
  font-weight: 600;
  font-size: 15px;
}

.blog-single.style-1 .dz-media img {
  border-radius: var(--border-radius-base);
}

.blog-single.style-1 .dz-media .dz-badge-list {
  position: absolute;
  top: 20px;
  left: 20px;
}

.blog-single.style-1 .dz-media .dz-badge {
  background-color: var(--primary);
  color: #fff;
  border-radius: var(--border-radius-base);
  padding: 8px 15px;
  font-size: 12px;
  text-transform: uppercase;
}

.blog-single.style-1:hover .dz-media {
  border-color: var(--primary);
}

.blog-single.style-1:hover .btn-link {
  color: var(--primary);
}

.dz-post-text a {
  text-decoration: underline;
  box-shadow: inset 0 -10px 0 var(--rgba-primary-1);
}

.dz-post-text .widget_archive li a,
.dz-post-text .wp-block-latest-posts li a,
.dz-post-text .wp-block-categories-list li a,
.dz-post-text .wp-block-archives-list li a,
.dz-post-text .widget_categories li a,
.dz-post-text blockquote a,
.dz-post-text .wp-block-button__link {
  box-shadow: none;
  text-decoration: none;
}

.post-header {
  position: relative;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {
  .post-header {
    margin-bottom: 1.25rem;
  }
}

.post-header .dz-media img {
  min-height: 250px;
  object-fit: cover;
}

.post-header .dz-info {
  position: absolute;
  background: -moz-linear-gradient(top,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.6) 100%);
  background: -webkit-linear-gradient(top,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.6) 100%);
  background: linear-gradient(to bottom,
      rgba(0, 0, 0, 0) 1%,
      rgba(0, 0, 0, 0.6) 100%);
  position: absolute;
  bottom: 0;
  text-align: center;
  padding: 100px 30px 30px 30px !important;
  width: 100%;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info {
    padding: 40px 15px 15px 15px !important;
  }
}

.post-header .dz-info .dz-title {
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .post-header .dz-info .dz-title {
    font-size: 1.125rem;
  }
}

.post-header .dz-info .dz-meta>ul {
  justify-content: center;
}

.post-header .dz-info .dz-meta>ul>li {
  color: #fff;
}

.post-link-in {
  padding: 15px 50px;
  background: rgba(0, 0, 0, 0.7);
  color: #fff;
  font-size: 22px;
  font-weight: 400;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.post-link-in:hover {
  background: var(--primary);
  color: #fff;
}

.side-bar {
  padding-bottom: 1px;
}

@media only screen and (max-width: 991px) {
  .side-bar {
    padding-left: 0;
    padding-right: 0;
  }
}

.side-bar.left {
  padding-left: 0;
  padding-right: 20px;
}

@media only screen and (max-width: 991px) {
  .side-bar.left {
    padding-right: 0;
    padding-left: 0;
  }
}

.alignnone {
  margin: 0.3125rem 0 1.563rem 0;
}

.alignnoneimg,
.alignnone.wp-caption,
.alignnone.is-resized {
  margin: 0.3125rem 0 1.563rem 0;
}

.aligncenter {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.aligncenterimg,
.aligncenter.wp-caption,
.aligncenter.is-resized {
  display: block;
  margin: 0.3125rem auto 0.938rem;
  text-align: center;
}

.alignright {
  float: right;
  margin: 0.3125rem 0 1.563rem 1.563rem;
}

.alignrightimg,
.alignright.wp-caption,
.alignright.is-resized {
  margin: 0.3125rem 0 1.563rem 1.563rem;
  float: right;
}

.alignleft {
  float: left;
  margin: 0.3125rem 1.563rem 1.563rem 0;
}

.alignleftimg,
.alignleft.wp-caption,
.alignleft.is-resized {
  margin: 0.3125rem 1.563rem 1.563rem 0;
  float: left;
}

.wp-caption {
  max-width: 100%;
  text-align: center;
}

.wp-caption img[class*="wp-image-"] {
  display: block;
  margin: 0;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 100%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 0.813rem;
  line-height: 1.125rem;
  margin: 0;
  padding: 0.625rem 0;
  text-align: left;
}

.wp-block-quote.is-large,
.wp-block-quote.is-style-large,
blockquote.wp-block-quote,
blockquote.wp-block-pullquote,
blockquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft,
blockquote {
  padding: 1.25rem 2rem 1.25rem 3rem;
  font-size: 0.938rem;
  color: var(--title);
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  line-height: 1.875rem;
  position: relative;
  clear: both;
  font-weight: 700;
  z-index: 1;
  border: 0;
}

@media only screen and (max-width: 991px) {

  .wp-block-quote.is-large,
  .wp-block-quote.is-style-large,
  blockquote.wp-block-quote,
  blockquote.wp-block-pullquote,
  blockquote.wp-block-pullquote.alignright,
  blockquote.wp-block-pullquote.alignleft,
  blockquote {
    padding: 1.25rem 1.25rem 0.938rem 1.25rem;
    font-size: 0.813rem;
  }
}

.wp-block-quote.is-large cite,
.wp-block-quote.is-style-large cite,
blockquote.wp-block-quote cite,
blockquote.wp-block-pullquote cite,
blockquote.wp-block-pullquote.alignright cite,
blockquote.wp-block-pullquote.alignleft cite,
blockquote cite {
  font-style: normal;
  position: relative;
  display: block;
  margin-top: 10px;
  font-weight: 600;
  color: var(--primary);
  line-height: 1.3;
  padding-left: 60px;
  font-family: var(--font-family-title);
}

.wp-block-quote.is-large cite:before,
.wp-block-quote.is-style-large cite:before,
blockquote.wp-block-quote cite:before,
blockquote.wp-block-pullquote cite:before,
blockquote.wp-block-pullquote.alignright cite:before,
blockquote.wp-block-pullquote.alignleft cite:before,
blockquote cite:before {
  position: absolute;
  content: "";
  width: 50px;
  height: 0.1px;
  top: 50%;
  transform: translateY(50%);
  left: 0;
  background: var(--primary);
}

.wp-block-quote.is-large b,
.wp-block-quote.is-large strong,
.wp-block-quote.is-large .strong,
.wp-block-quote.is-style-large b,
.wp-block-quote.is-style-large strong,
.wp-block-quote.is-style-large .strong,
blockquote.wp-block-quote b,
blockquote.wp-block-quote strong,
blockquote.wp-block-quote .strong,
blockquote.wp-block-pullquote b,
blockquote.wp-block-pullquote strong,
blockquote.wp-block-pullquote .strong,
blockquote.wp-block-pullquote.alignright b,
blockquote.wp-block-pullquote.alignright strong,
blockquote.wp-block-pullquote.alignright .strong,
blockquote.wp-block-pullquote.alignleft b,
blockquote.wp-block-pullquote.alignleft strong,
blockquote.wp-block-pullquote.alignleft .strong,
blockquote b,
blockquote strong,
blockquote .strong {
  color: inherit;
}

.wp-block-quote.is-large.wp-block-pullquote.alignleft,
.wp-block-quote.is-style-large.wp-block-pullquote.alignleft,
blockquote.wp-block-quote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignleft,
blockquote.wp-block-pullquote.alignleft {
  margin: 0rem 1.563rem 1.563rem 0rem;
}

.wp-block-quote.is-large.wp-block-pullquote.alignright,
.wp-block-quote.is-style-large.wp-block-pullquote.alignright,
blockquote.wp-block-quote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignleft.wp-block-pullquote.alignright,
blockquote.wp-block-pullquote.alignright {
  margin: 0rem 0 1.563rem 1.563rem;
}

.wp-block-quote.is-large.style-1,
.wp-block-quote.is-style-large.style-1,
blockquote.wp-block-quote.style-1,
blockquote.wp-block-pullquote.style-1,
blockquote.wp-block-pullquote.alignright.style-1,
blockquote.wp-block-pullquote.alignleft.style-1,
blockquote.style-1 {
  background: #f3f4f8;
  border-radius: var(--border-radius-base);
  color: var(--primary);
  padding: 25px 40px;
  overflow: hidden;
}

@media only screen and (max-width: 575px) {

  .wp-block-quote.is-large.style-1,
  .wp-block-quote.is-style-large.style-1,
  blockquote.wp-block-quote.style-1,
  blockquote.wp-block-pullquote.style-1,
  blockquote.wp-block-pullquote.alignright.style-1,
  blockquote.wp-block-pullquote.alignleft.style-1,
  blockquote.style-1 {
    padding: 25px 30px;
  }
}

.wp-block-quote.is-large.style-1:after,
.wp-block-quote.is-style-large.style-1:after,
blockquote.wp-block-quote.style-1:after,
blockquote.wp-block-pullquote.style-1:after,
blockquote.wp-block-pullquote.alignright.style-1:after,
blockquote.wp-block-pullquote.alignleft.style-1:after,
blockquote.style-1:after {
  content: "";
  width: 8px;
  height: 100%;
  left: 0;
  top: 0;
  background: var(--primary);
  position: absolute;
}

@media only screen and (max-width: 767px) {

  .wp-block-quote.is-large.style-1:after,
  .wp-block-quote.is-style-large.style-1:after,
  blockquote.wp-block-quote.style-1:after,
  blockquote.wp-block-pullquote.style-1:after,
  blockquote.wp-block-pullquote.alignright.style-1:after,
  blockquote.wp-block-pullquote.alignleft.style-1:after,
  blockquote.style-1:after {
    width: 5px;
  }
}

.wp-block-quote.is-large.style-1:before,
.wp-block-quote.is-style-large.style-1:before,
blockquote.wp-block-quote.style-1:before,
blockquote.wp-block-pullquote.style-1:before,
blockquote.wp-block-pullquote.alignright.style-1:before,
blockquote.wp-block-pullquote.alignleft.style-1:before,
blockquote.style-1:before {
  position: absolute;
  content: "\f10e";
  font-family: "Font Awesome 6 Free";
  color: var(--primary);
  bottom: 15px;
  right: 25px;
  line-height: 1;
  font-weight: 900;
  opacity: 0.2;
  font-size: 60px;
}

@media only screen and (max-width: 767px) {

  .wp-block-quote.is-large.style-1:before,
  .wp-block-quote.is-style-large.style-1:before,
  blockquote.wp-block-quote.style-1:before,
  blockquote.wp-block-pullquote.style-1:before,
  blockquote.wp-block-pullquote.alignright.style-1:before,
  blockquote.wp-block-pullquote.alignleft.style-1:before,
  blockquote.style-1:before {
    font-size: 45px;
    bottom: 20px;
    right: 20px;
  }
}

blockquote.wp-block-pullquote:not(.is-style-solid-color) {
  background: #5608e0;
}

.wp-caption-text {
  font-size: 0.875rem;
  line-height: 1.3;
}

.dz-card.blog-single .dz-post-text blockquote p,
.dz-page-text blockquote p,
blockquote p {
  font-size: 20px;
  line-height: 1.6;
  color: var(--title);
  margin-bottom: 1rem;
  font-weight: 600;
  font-style: italic;
}

.dz-card.blog-single .dz-post-text blockquote p cite,
.dz-page-text blockquote p cite,
blockquote p cite {
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {

  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1.125rem;
  }
}

@media only screen and (max-width: 575px) {

  .dz-card.blog-single .dz-post-text blockquote p,
  .dz-page-text blockquote p,
  blockquote p {
    font-size: 1rem;
  }
}

.dz-page-text {
  padding-bottom: 30px !important;
}

.size-auto,
.size-full,
.size-large,
.size-medium,
.size-thumbnail {
  max-width: 100%;
  height: auto;
}

.min-container,
.dz-page-text,
.dz-page-text+#comment-list,
.dz-page-text+#comment-list+.paginate-links {
  max-width: 51.875rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0.938rem;
  padding-right: 0.938rem;
}

.dz-card.blog-single.sidebar+#comment-list {
  padding: 0;
  max-width: 100%;
}

.max-container {
  max-width: 93.75rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 3.4375rem;
  padding-right: 3.4375rem;
}

.dz-page-text+#comment-list+.paginate-links,
.dz-page-text+#comment-list,
.dz-page-text {
  padding: 0;
}

.dz-page-text.sidebar {
  width: 100%;
  padding: 0;
  max-width: 100%;
}

.dz-page-text>ol,
.dz-page-text>ul,
.dz-page-text>.wp-block-gallery,
.dz-card.blog-single .dz-post-text>.wp-block-gallery,
.dz-card.blog-single .dz-post-text>ol,
.dz-card.blog-single .dz-post-text>ul {
  list-style: none;
  margin-bottom: 1.875rem;
}

.dz-page-text>ol,
.dz-page-text>ul,
.dz-card.blog-single .dz-post-text>ol,
.dz-card.blog-single .dz-post-text>ul,
.dz-card.blog-single .dz-post-text.text>ol,
.dz-card.blog-single .dz-post-text.text>ul {
  padding-left: 1.25rem;
}

.dz-page-text>ol ol,
.dz-page-text>ol ul,
.dz-page-text>ul ol,
.dz-page-text>ul ul,
.dz-card.blog-single .dz-post-text>ol ol,
.dz-card.blog-single .dz-post-text>ol ul,
.dz-card.blog-single .dz-post-text>ul ol,
.dz-card.blog-single .dz-post-text>ul ul,
.dz-card.blog-single .dz-post-text.text>ol ol,
.dz-card.blog-single .dz-post-text.text>ol ul,
.dz-card.blog-single .dz-post-text.text>ul ol,
.dz-card.blog-single .dz-post-text.text>ul ul {
  padding-left: 1.563rem;
}

.dz-page-text>ol li,
.dz-page-text>ul li,
.dz-card.blog-single .dz-post-text>ol li,
.dz-card.blog-single .dz-post-text>ul li,
.dz-card.blog-single .dz-post-text>ol li,
.dz-card.blog-single .dz-post-text>ul li,
.dz-card.blog-single .dz-post-text.text>ol li,
.dz-card.blog-single .dz-post-text.text>ul li {
  padding: 0.5rem 0.5rem;
  position: relative;
  list-style: inherit;
}

.dz-page-text>.wp-block-gallery,
.dz-card.blog-single .dz-post-text>.wp-block-gallery,
.dz-card.blog-single .dz-post-text.text>.wp-block-gallery {
  padding: 0;
  list-style: none;
}

.dz-page-text>.wp-block-gallery li,
.dz-card.blog-single .dz-post-text>.wp-block-gallery li,
.dz-card.blog-single .dz-post-text.text>.wp-block-gallery li {
  padding: 0;
  list-style: none;
  margin: 0.25rem;
}

.dz-page-text>.wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text>.wp-block-gallery li:before,
.dz-card.blog-single .dz-post-text.text>.wp-block-gallery li:before {
  content: none;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  margin: 0 0.5rem 0.5rem 0;
}

.wp-block-gallery.columns-4 .blocks-gallery-image,
.wp-block-gallery.columns-4 .blocks-gallery-item,
.wp-block-gallery.columns-3 .blocks-gallery-image,
.wp-block-gallery.columns-3 .blocks-gallery-item {
  margin-right: 0.5rem;
}

.content-spacious-full .wp-block-gallery {
  margin-left: calc(-12.5rem - (0.313rem / 2));
  margin-right: calc(-12.5rem - (0.313rem / 2));
  width: calc(100% + 25rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignwide {
  margin-left: calc(-6.25rem - (0.313rem / 2));
  margin-right: calc(-6.25rem - (0.313rem / 2));
  width: calc(100% + 12.5rem + 0.313rem);
  max-width: initial;
}

.alignfull,
.alignfullwide {
  margin-left: calc(50% - 50vw - (var(--content-share-ml, 0px) / 2));
  width: 100vw;
  max-width: 100vw;
  box-sizing: border-box;
}

.dz-page-text h1,
.dz-page-text .h1,
.dz-post-text h1,
.dz-post-text .h1,
.thm-unit-test .dz-post-text h1,
.thm-unit-test .dz-post-text .h1 {
  margin-bottom: 1rem;
}

.dz-page-text h2,
.dz-page-text .h2,
.dz-post-text h2,
.dz-post-text .h2,
.thm-unit-test .dz-post-text h2,
.thm-unit-test .dz-post-text .h2 {
  margin-bottom: 1rem;
}

.dz-page-text h3,
.dz-page-text .h3,
.dz-post-text h3,
.dz-post-text .h3,
.thm-unit-test .dz-post-text h3,
.thm-unit-test .dz-post-text .h3 {
  margin-bottom: 1rem;
  font-weight: 700;
}

.dz-page-text h4,
.dz-page-text .h4,
.dz-page-text .comment-reply-title,
.dz-page-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-page-text .wp-block-search__label,
.dz-post-text h4,
.dz-post-text .h4,
.dz-post-text .comment-reply-title,
.dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .dz-post-text .wp-block-search__label,
.thm-unit-test .dz-post-text h4,
.thm-unit-test .dz-post-text .h4,
.thm-unit-test .dz-post-text .comment-reply-title,
.thm-unit-test .dz-post-text .wp-block-search .wp-block-search__label,
.wp-block-search .thm-unit-test .dz-post-text .wp-block-search__label {
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dz-page-text h5,
.dz-page-text .h5,
.dz-post-text h5,
.dz-post-text .h5,
.thm-unit-test .dz-post-text h5,
.thm-unit-test .dz-post-text .h5 {
  margin-bottom: 0.75rem;
}

.dz-page-text h6,
.dz-page-text .h6,
.dz-post-text h6,
.dz-post-text .h6,
.thm-unit-test .dz-post-text h6,
.thm-unit-test .dz-post-text .h6 {
  margin-bottom: 0.625rem;
}

.wp-block-code,
pre.wp-block-code,
pre {
  margin: 0 0 1.563rem;
  padding: 1.25rem;
  color: #fff;
  background-color: #212326;
  white-space: pre;
  font-size: 0.938rem;
  border-radius: 0.1875rem;
}

.dz-page-text,
.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li,
.dz-card.blog-single .dz-card.blog-single .dz-post-text p:not(.has-text-color):not(.has-text-align-center):not(.has-text-align-left):not(.has-text-align-right) {
  font-size: 1rem;
  line-height: 1.7;
}

.wp-block-cover,
.wp-block-cover-image {
  color: #fff;
}

.wp-block-cover p:last-child,
.wp-block-cover-image p:last-child {
  margin-bottom: 0;
}

.wp-block-quote.has-text-align-right {
  border-right: 0;
}

.dz-page-text ul li,
.dz-page-text ol li,
.dz-card.blog-single .dz-post-text ul li,
.dz-card.blog-single .dz-post-text ol li {
  position: relative;
}

.dz-page-text ul>li:before,
.dz-page-text ol>li:before,
.dz-card.blog-single .dz-post-text ul>li:before,
.dz-card.blog-single .dz-post-text ol>li:before {
  content: "";
  display: inline-block;
  width: 0.375rem;
  height: 0.375rem;
  background: #212529;
  left: -0.938rem;
  position: absolute;
  top: 1.1rem;
  border-radius: 4px;
}

.dz-page-text ul>li li:before,
.dz-page-text ol>li li:before,
.dz-card.blog-single .dz-post-text ul>li li:before,
.dz-card.blog-single .dz-post-text ol>li li:before {
  content: none;
}

.dz-page-text p,
.dz-card.blog-single .dz-post-text p {
  margin-bottom: 1.65rem;
}

.paginate-links {
  display: flex;
  align-items: center;
  margin: 1.5rem 0 4rem 0;
}

.paginate-links a,
.paginate-links>span {
  margin: 0 0 0 0.625rem;
  position: relative;
  border: 0rem solid #6cc000;
  color: #1f2471;
  display: inline-block;
  font-size: 1rem;
  font-weight: 500;
  line-height: 2.5rem;
  min-width: 2.5rem;
  height: 2.5rem;
  text-align: center;
  text-transform: capitalize;
  transition: all 500ms ease 0s;
  background: #e0e0e0;
  border-radius: 0.1875rem;
}

.wp-block-columns {
  margin-bottom: 0;
}

.wp-block-gallery {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  position: relative;
}

.wp-block-gallery .blocks-gallery-image figure,
.wp-block-gallery .blocks-gallery-item figure {
  height: 100%;
  margin: 0;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .blocks-gallery-image figure,
  .wp-block-gallery .blocks-gallery-item figure {
    align-items: flex-end;
    display: flex;
    justify-content: flex-start;
  }
}

.wp-block-gallery .blocks-gallery-image img,
.wp-block-gallery .blocks-gallery-item img {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery .blocks-gallery-image img,
  .wp-block-gallery .blocks-gallery-item img {
    width: auto;
  }
}

.wp-block-gallery .blocks-gallery-image figcaption,
.wp-block-gallery .blocks-gallery-item figcaption {
  background: linear-gradient(0deg,
      rgba(0, 0, 0, 0.7),
      rgba(0, 0, 0, 0.3) 60%,
      transparent);
  bottom: 0;
  color: #fff;
  font-size: 0.813rem;
  max-height: 100%;
  overflow: auto;
  padding: 2.5rem 0.625rem 0.3125rem;
  position: absolute;
  text-align: center;
  width: 100%;
}

.wp-block-gallery .blocks-gallery-image figcaption img,
.wp-block-gallery .blocks-gallery-item figcaption img {
  display: inline;
}

.wp-block-gallery.is-cropped .blocks-gallery-image a,
.wp-block-gallery.is-cropped .blocks-gallery-image img,
.wp-block-gallery.is-cropped .blocks-gallery-item a,
.wp-block-gallery.is-cropped .blocks-gallery-item img {
  width: 100%;
}

@supports (position: -webkit-sticky) or (position: sticky) {

  .wp-block-gallery.is-cropped .blocks-gallery-image a,
  .wp-block-gallery.is-cropped .blocks-gallery-image img,
  .wp-block-gallery.is-cropped .blocks-gallery-item a,
  .wp-block-gallery.is-cropped .blocks-gallery-item img {
    -o-object-fit: cover;
    flex: 1;
    height: 100%;
    object-fit: cover;
  }
}

.wp-block-gallery .blocks-gallery-image,
.wp-block-gallery .blocks-gallery-item {
  width: calc(50% - 0.5rem);
}

.wp-block-gallery .blocks-gallery-image:nth-of-type(2n),
.wp-block-gallery .blocks-gallery-item:nth-of-type(2n) {
  margin-right: 0;
}

.wp-block-gallery.columns-1 .blocks-gallery-image,
.wp-block-gallery.columns-1 .blocks-gallery-item {
  margin-right: 0;
  width: 100%;
}

@media only screen and (max-width: 64rem) {
  .alignwide {
    margin-left: 0;
    margin-right: 0;
    width: 100% !important;
    max-width: 100% !important;
  }

  .alignwide .alignleft img {
    width: 100%;
  }

  .alignwide .alignleft {
    margin: 0.3125rem 0rem 1.563rem 0;
    float: none;
  }
}

@media (min-width: 37.5rem) {

  .wp-block-gallery.columns-3 .blocks-gallery-image,
  .wp-block-gallery.columns-3 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(33.33333% - 0.666rem);
  }

  .wp-block-gallery.columns-4 .blocks-gallery-image,
  .wp-block-gallery.columns-4 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(25% - 0.75rem);
  }

  .wp-block-gallery.columns-5 .blocks-gallery-image,
  .wp-block-gallery.columns-5 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(20% - 0.8rem);
  }

  .wp-block-gallery.columns-6 .blocks-gallery-image,
  .wp-block-gallery.columns-6 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(16.66667% - 0.833rem);
  }

  .wp-block-gallery.columns-7 .blocks-gallery-image,
  .wp-block-gallery.columns-7 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(14.28571% - 0.857rem);
  }

  .wp-block-gallery.columns-8 .blocks-gallery-image,
  .wp-block-gallery.columns-8 .blocks-gallery-item {
    margin-right: 1rem;
    width: calc(12.5% - 0.875rem);
  }

  .wp-block-gallery.columns-1 .blocks-gallery-image:nth-of-type(1n),
  .wp-block-gallery.columns-1 .blocks-gallery-item:nth-of-type(1n),
  .wp-block-gallery.columns-2 .blocks-gallery-image:nth-of-type(2n),
  .wp-block-gallery.columns-2 .blocks-gallery-item:nth-of-type(2n),
  .wp-block-gallery.columns-3 .blocks-gallery-image:nth-of-type(3n),
  .wp-block-gallery.columns-3 .blocks-gallery-item:nth-of-type(3n),
  .wp-block-gallery.columns-4 .blocks-gallery-image:nth-of-type(4n),
  .wp-block-gallery.columns-4 .blocks-gallery-item:nth-of-type(4n),
  .wp-block-gallery.columns-5 .blocks-gallery-image:nth-of-type(5n),
  .wp-block-gallery.columns-5 .blocks-gallery-item:nth-of-type(5n),
  .wp-block-gallery.columns-6 .blocks-gallery-image:nth-of-type(6n),
  .wp-block-gallery.columns-6 .blocks-gallery-item:nth-of-type(6n),
  .wp-block-gallery.columns-7 .blocks-gallery-image:nth-of-type(7n),
  .wp-block-gallery.columns-7 .blocks-gallery-item:nth-of-type(7n),
  .wp-block-gallery.columns-8 .blocks-gallery-image:nth-of-type(8n),
  .wp-block-gallery.columns-8 .blocks-gallery-item:nth-of-type(8n) {
    margin-right: 0;
  }
}

.wp-block-gallery .blocks-gallery-image:last-child,
.wp-block-gallery .blocks-gallery-item:last-child {
  margin-right: 0;
}

.wp-block-gallery .blocks-gallery-item.has-add-item-button {
  width: 100%;
}

.wp-block-image.alignfullwide img {
  border-radius: 0;
}

.wp-block-image img {
  border-radius: 0.625rem;
}

.wp-block-cover,
.wp-block-cover-image,
.wp-block-embed,
.wp-block-image {
  margin-bottom: 1.875rem;
  margin-top: 1.875rem;
}

.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  max-width: 19.0625rem;
  width: 100%;
}

.wp-block-gallery.aligncenter,
.wp-block-gallery.alignleft,
.wp-block-gallery.alignright {
  display: flex;
}

.wp-block-gallery.aligncenter .blocks-gallery-item figure {
  justify-content: center;
}

.dz-page-text.sidebar .alignfull,
.dz-page-text.sidebar .alignfullwide,
.dz-page-text.sidebar .alignwide,
.dz-page-text.sidebar .wp-block-gallery,
.blog-single.dz-card.sidebar .alignfullwide,
.blog-single.dz-card.sidebar .alignwide,
.blog-single.dz-card.sidebar .dz-post-text .wp-block-gallery {
  margin-left: 0;
  margin-right: 0;
  width: 100%;
  max-width: initial;
}

.blog-overlap {
  background: #fff;
  margin-top: -8.75rem;
  padding: 1.25rem 1.25rem 0;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
}

.blog-single.dz-card.sidebar {
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.dz-post-text table,
.dz-page-text table,
.wp-block-table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 1rem;
  background-color: transparent;
  font-size: 1rem;
  margin-bottom: 1.875rem;
}

.wp-block-image figcaption,
.dz-post-text figcaption,
.dz-page-text figcaption {
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
  font-style: normal;
  margin-top: 0.625rem;
}

.dz-post-text table tbody tr:nth-of-type(odd),
.dz-page-text table tbody tr:nth-of-type(odd),
.wp-block-table tbody tr:nth-of-type(odd) {
  background-color: #fafafa;
}

.dz-post-text td,
.dz-post-text th,
.dz-page-text td,
.dz-page-text th,
.wp-block-table td,
.wp-block-table th {
  padding: 0.625rem 0.938rem;
  border: 0.0625rem solid #e4e4e4;
  border-right: 0;
  border-left: 0;
}

.wp-block-media-text {
  margin-bottom: 30px;
}

.wp-block-media-text .wp-block-media-text__content {
  padding: 0 1.875rem;
}

.wp-block-column ol li:before,
.wp-block-column ul li:before {
  content: none !important;
}

.wp-block-pullquote {
  padding: 1em 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote cite {
  color: #fff;
}

.wp-block-pullquote.alignright,
.wp-block-pullquote.alignleft {
  padding: 0;
  border-top: 0;
  border-bottom: 0;
}

.wp-block-pullquote.alignright blockquote,
.wp-block-pullquote.alignleft blockquote {
  margin: 0;
}

.dz-post-text .wp-block-cover-image-text,
.dz-post-text .wp-block-cover-text,
.dz-post-text section.wp-block-cover-image h2,
.dz-post-text section.wp-block-cover-image .h2,
.dz-page-text .wp-block-cover-image-text,
.dz-page-text .wp-block-cover-text,
.dz-page-text section.wp-block-cover-image h2,
.dz-page-text section.wp-block-cover-image .h2 {
  color: #fff;
}

.dz-post-text .bootstrap-select .dropdown-menu li a,
.dz-page-text .bootstrap-select .dropdown-menu li a {
  box-shadow: none;
  font-size: 0.875rem;
  font-family: "Poppins", sans-serif;
}

.dz-post-text .wp-block-calendar a {
  box-shadow: none !important;
  text-decoration: unset;
}

.wp-block-search,
.wp-block-archives,
.wp-block-latest-posts,
.wp-block-latest-comments,
.wp-block-categories,
.wp-block-calendar {
  margin-bottom: 2.5rem;
}

.admin-bar .is-fixed .main-bar {
  top: 1.875rem;
}

.wp-block-quote.is-style-large cite:before {
  display: none;
}

.post-password-form label {
  font-size: 1rem;
}

.post-password-form input[type="submit"] {
  padding: 0.625rem 1.563rem;
  background-color: var(--primary);
  border: none;
  height: 2.8125rem;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
}

.post-password-form input[type="submit"]:hover {
  background-color: var(--primary);
}

.post-password-form input[type="password"] {
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.wp-block-search .wp-block-search__button {
  background: var(--primary);
  border: 0;
  color: #fff;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
  padding: 0.625rem 1.563rem;
  -webkit-transition: all 1s;
  -ms-transition: all 1s;
  transition: all 1s;
}

.wp-block-search .wp-block-search__input {
  border: 0.0625rem solid #e1e6eb;
  height: 2.8125rem;
  padding: 0.625rem 1.25rem;
  font-size: 0.875rem;
  outline: none;
}

.wp-block-tag-cloud a {
  padding: 0.5rem 0.625rem;
  background: var(--primary);
  font-size: 0.75rem;
  display: inline-block;
  margin: 0 0 0.3125rem;
  color: #fff;
  font-weight: 600;
  transition: all 0.5s;
  -moz-transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  text-decoration: none;
  box-shadow: none;
}

.wp-block-tag-cloud a:hover {
  background: var(--primary-hover);
}

.wp-block-latest-comments {
  padding: 0 !important;
}

.wp-block-latest-comments .avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-excerpt {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  color: var(--title);
  font-family: "Poppins", sans-serif;
  font-size: 1.063rem;
  font-weight: 500;
  line-height: 1.7;
  margin-bottom: 0.625rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta a {
  color: var(--title);
  box-shadow: none;
  text-decoration: none;
}

.wp-block-latest-comments img+article .wp-block-latest-comments__comment .wp-block-latest-comments__comment-meta {
  margin-left: 4.375rem;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-meta time {
  display: block;
  width: 100%;
  color: #9fa1a4;
  font-size: 0.813rem;
  font-weight: 400;
}

.wp-block-latest-comments .wp-block-latest-comments__comment-excerpt p {
  font-size: 1rem !important;
  line-height: 1.5 !important;
  margin-bottom: 0.625rem !important;
}

.wp-block-latest-comments li {
  padding: 0 !important;
  border-bottom: 0.0625rem solid #eee;
  padding-bottom: 0.625rem !important;
}

.wp-block-latest-comments li:before {
  content: none !important;
}

.wp-block-latest-comments__comment-avatar {
  width: 3.4375rem;
  height: 3.4375rem;
  border-radius: 3.4375rem;
}

.comment-content.dz-page-text {
  max-width: 100%;
}

.wp-block-rss {
  padding: 0 !important;
}

.wp-block-rss .wp-block-rss__item {
  padding: 0.3125rem 0 !important;
  border-bottom: 0.0625rem solid #eee;
}

.wp-block-rss .wp-block-rss__item:before {
  content: none !important;
}

.wp-block-rss .wp-block-rss__item a {
  font-family: "Poppins", sans-serif;
  font-size: 1.125rem;
  box-shadow: unset !important;
  font-weight: 600;
  color: var(--title);
  text-decoration: none;
}

@media only screen and (max-width: 75rem) {

  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: calc(-6.25rem - (0.625rem / 2));
    margin-right: calc(-6.25rem - (0.625rem / 2));
    width: calc(100% + 12.5rem + 0.625rem);
  }
}

@media only screen and (max-width: 61.9375rem) {
  .admin-bar .mo-left .header-nav {
    top: 1.875rem;
    height: calc(100vh - 1.875rem) !important;
  }

  .dz-page-text .wp-block-gallery,
  .dz-card.blog-single .dz-post-text .wp-block-gallery {
    margin-left: 0;
    margin-right: 0;
    width: 100%;
  }

  .dz-page-text,
  .dz-page-text ul li,
  .dz-page-text ol li,
  .dz-page-text p,
  .dz-card.blog-single .dz-post-text,
  .dz-card.blog-single .dz-post-text ul li,
  .dz-card.blog-single .dz-post-text ol li,
  .dz-card.blog-single .dz-post-text p {
    font-size: 1rem;
  }
}

@media only screen and (max-width: 48.875rem) {
  .admin-bar .is-fixed .main-bar {
    top: 2.8125rem;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .wp-block-media-text {
    display: block;
  }

  .wp-block-media-text .wp-block-media-text__media {
    margin-bottom: 0.938rem;
  }

  .wp-block-media-text .wp-block-media-text__content {
    padding: 0;
  }
}

@media only screen and (max-width: 37.5rem) {
  .admin-bar .is-fixed .main-bar {
    top: 0;
  }

  .admin-bar .mo-left .header-nav {
    top: 2.8125rem;
    height: calc(100vh - 2.812rem) !important;
  }

  .admin-bar .mo-left .is-fixed .header-nav {
    top: 0;
    height: 100vh !important;
  }
}

.post-footer {
  border-top: 0.0625rem solid rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.938rem 0 0;
}

.post-footer .dz-meta .tag-list {
  padding-bottom: 0;
  text-align: left;
  margin: 0;
  padding: 0;
  list-style: none;
  text-transform: capitalize;
  display: inline;
}

.post-footer .dz-meta ul li {
  margin-right: 0.3125rem;
  padding: 0;
  display: inline-block;
  color: #333333;
  font-weight: 500;
  font-size: 0.938rem;
  font-style: italic;
}

.post-footer .post-tag a {
  text-transform: capitalize;
  font-size: 0.938rem;
  color: #999;
  background: transparent;
  padding: 0;
  border: 0;
  line-height: 0.875rem;
  font-weight: 500;
}

.share-post li {
  display: inline-block;
}

.share-post a {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 2.1875rem;
  border: 0.0625rem solid #eee;
  display: block;
  text-align: center;
  line-height: 2.0625rem;
  color: #212529;
}

.extra-blog {
  margin-bottom: 30px;
}

@media only screen and (max-width: 575px) {
  .comment-respond.style-1 .comment-reply-title {
    margin-bottom: 20px;
  }
}

.default-form.comment-respond .comment-reply-title,
.comments-area .comments-title,
.extra-blog .blog-title {
  font-size: 23px;
  font-weight: 800;
}

.comments-area .comment-list {
  margin-bottom: 60px;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-list {
    margin-bottom: 40px;
  }
}

.comments-area .comment-list .default-form {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.comments-area .comment-list .default-form small,
.comments-area .comment-list .default-form .small {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
}

.comments-area .comment-list .default-form small a,
.comments-area .comment-list .default-form .small a {
  color: #e10000;
}

.comments-area .comment-list .dz-page-text {
  padding-bottom: 0 !important;
  margin: 0;
}

.comments-area .comment-list>.comment .comment-body {
  position: relative;
  padding-left: 120px;
  margin-bottom: 30px;
  min-height: 95px;
  border-bottom: 1px solid #d3d3d3;
  padding-bottom: 25px;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-list>.comment .comment-body {
    padding-bottom: 15px;
    padding-left: 75px;
    margin-bottom: 20px;
  }
}

.comments-area .comment-list>.comment .comment-body .comment-author .avatar {
  position: absolute;
  left: 0;
  height: 100px;
  width: 100px;
  border-radius: var(--border-radius-base);
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-list>.comment .comment-body .comment-author .avatar {
    height: 60px;
    width: 60px;
  }
}

.comments-area .comment-list>.comment .comment-body .comment-author .fn {
  font-size: 1.25rem;
  line-height: 1.2;
  font-family: var(--font-family-title);
  color: var(--title);
  font-weight: 600;
  font-style: normal;
  margin-bottom: 5px;
  display: block;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-list>.comment .comment-body .comment-author .fn {
    font-size: 1.125rem;
  }
}

.comments-area .comment-list>.comment .comment-body .comment-meta {
  margin-bottom: 5px;
}

.comments-area .comment-list>.comment .comment-body .comment-meta a {
  color: var(--secondary);
}

.comments-area .comment-list>.comment .comment-body p {
  margin-bottom: 10px;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 14px;
  color: var(--primary);
  display: inline-block;
  line-height: 1.3;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.comments-area .comment-list>.comment .comment-body .reply .comment-reply-link i {
  margin-right: 8px;
}

.comments-area .comment-list>.comment .children {
  padding-left: 120px;
}

@media only screen and (max-width: 767px) {
  .comments-area .comment-list>.comment .children {
    padding-left: 75px;
  }
}

@media only screen and (max-width: 575px) {
  .comments-area .comment-list>.comment .children {
    padding-left: 30px;
  }
}

.comment-reply-title a {
  font-size: 16px;
  font-weight: 600;
}

.comment-respond .comment-form {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}

.comment-respond .comment-form p {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond .comment-form p label {
  display: none;
}

.comment-respond .comment-form p textarea {
  height: 120px !important;
}

.comment-respond .comment-form .comment-form-comment {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.comment-respond .comment-form .comment-form-author,
.comment-respond .comment-form .comment-form-email,
.comment-respond .comment-form .comment-form-url {
  width: 50%;
}

@media only screen and (max-width: 575px) {

  .comment-respond .comment-form .comment-form-author,
  .comment-respond .comment-form .comment-form-email,
  .comment-respond .comment-form .comment-form-url {
    width: 100% !important;
  }
}

.dz-page-text [class*="galleryid-"],
.dz-post-text [class*="galleryid-"] {
  clear: both;
  margin: 0 auto;
  overflow: hidden;
}

.gallery .gallery-item {
  width: 100%;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  padding: 0.3125rem;
  position: relative;
  box-sizing: border-box;
}

.gallery .gallery-item img {
  float: left;
  padding: 0 0rem;
  width: 100%;
  border: none !important;
}

.gallery .gallery-caption {
  font-size: 0.813rem;
  color: #707070;
  display: block;
  font-family: "Noto Sans", sans-serif;
  line-height: 1.5;
  padding: 0.5em 0;
  clear: both;
}

.gallery-columns-6 .gallery-caption,
.gallery-columns-7 .gallery-caption,
.gallery-columns-8 .gallery-caption,
.gallery-columns-9 .gallery-caption {
  display: none;
}

.gallery-columns-2 .gallery-item {
  max-width: 50%;
}

.gallery-columns-3 .gallery-item {
  max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
  max-width: 25%;
}

.gallery-columns-5 .gallery-item {
  max-width: 20%;
}

.gallery-columns-6 .gallery-item {
  max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
  max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
  max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
  max-width: 11.11%;
}

.gallery-icon img {
  margin: 0 auto;
}

.post-password-form {
  position: relative;
  clear: both;
}

.post-password-form label {
  display: block;
  font-size: 1rem;
}

.post-password-form input[type="password"] {
  width: 100%;
  border: 0.0625rem solid #ebedf2;
  padding: 0.625rem 6.25rem 0.625rem 0.938rem;
  height: 2.8125rem;
  border: 0.0625rem solid #ced4da;
}

.post-password-form input[type="submit"] {
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 0.625rem 1.25rem;
  background: var(--primary);
  color: #fff;
  border: none;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.875rem;
  outline: none;
  height: 2.8125rem;
}

.post-password-form input[type="submit"]:hover {
  background: var(--primary-hover);
}

.blog-post-banner {
  min-height: 300px;
  padding-top: 70px;
  padding-bottom: 70px;
}

.blog-post-banner .dz-title {
  color: #fff;
  font-size: 32px;
  margin-bottom: 20px;
}

.blog-post-banner .dz-meta {
  color: #fff;
}

.blog-post-banner .dz-meta ul {
  display: flex;
  opacity: 0.7;
}

.blog-post-banner .dz-meta ul li {
  margin-right: 20px;
}

.blog-post-banner .dz-meta ul li a {
  color: #fff;
}

.post-outside {
  margin-top: -120px;
}

.single-post .main-bar {
  border-bottom: 1px solid #eee;
}

.dz-load-more i:before,
.loadmore-btn i:before {
  -webkit-animation: unset !important;
  -moz-animation: unset !important;
  animation: unset !important;
}

.dz-share-post {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;
  border-top: 1px dashed #cccccc;
}

.dz-share-post .post-tags {
  display: flex;
  align-items: center;
}

.dz-share-post .post-tags .title {
  font-size: 18px;
  font-weight: 500;
  color: var(--title);
  margin-right: 10px;
  font-family: var(--font-family-title);
}

.dz-share-post .post-tags a {
  border-radius: var(--border-radius-base);
  padding: 6px 12px;
  border: 1px solid;
  line-height: 1.4;
  display: inline-block;
  color: inherit;
  font-size: 14px;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.dz-share-post .post-tags a i {
  color: var(--primary);
}

.dz-share-post .post-tags a:last-child {
  margin-right: 0;
}

.dz-share-post .post-tags a:hover {
  background-color: var(--primary);
  border-color: var(--primary);
  box-shadow: 0px 12px 20px 0px var(--rgba-primary-3);
  color: #fff;
}

.dz-share-post .post-tags a:hover i {
  color: #fff;
}

.dz-share-post .dz-social-icon {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 991px) {
  .dz-share-post .dz-social-icon {
    margin-bottom: 15px;
  }
}

.dz-share-post .dz-social-icon .title {
  margin-right: 20px;
  margin-bottom: 0;
}

.dz-share-post .dz-social-icon ul {
  margin-left: -3px;
  margin-right: -3px;
  display: flex;
}

.dz-share-post .dz-social-icon ul li {
  padding: 0 3px;
}

.dz-share-post .dz-social-icon ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  display: block;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  line-height: 35px;
  border-radius: var(--border-radius-base);
}

.dz-share-post .dz-social-icon ul li a:hover {
  color: #fff;
  background-color: var(--primary-hover);
}

@media only screen and (max-width: 1280px) {
  .dz-share-post .dz-social-icon {
    padding-top: 20px;
  }
}

@media only screen and (max-width: 991px) {
  .dz-share-post {
    display: block;
  }
}

@media only screen and (max-width: 767px) {
  .dz-share-post {
    display: block;
  }

  .dz-share-post .post-tags {
    margin-bottom: 20px;
  }
}

#comment-list:empty+.paginate-links {
  margin-top: -30px;
}

.post-swiper {
  position: relative;
}

.post-swiper .prev-post-swiper-btn,
.post-swiper .next-post-swiper-btn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  border-radius: var(--border-radius-base);
  text-align: center;
  line-height: 40px;
  font-size: 20px;
  background-color: #fff;
  color: var(--primary);
  z-index: 1;
  -webkit-transition: all 0.8s;
  -ms-transition: all 0.8s;
  transition: all 0.8s;
}

.post-swiper .prev-post-swiper-btn:hover,
.post-swiper .next-post-swiper-btn:hover {
  background-color: var(--primary);
  color: #fff;
}

.post-swiper .prev-post-swiper-btn {
  left: 10px;
}

.post-swiper .next-post-swiper-btn {
  right: 10px;
}

.blog-single .dz-media+.dz-info {
  padding: 30px 0 0;
}

.author-box {
  padding: 35px;
  background: #f3f4f8;
  border-radius: var(--border-radius-base);
}

.author-box .author-profile-info {
  display: flex;
}

.author-box .author-profile-info .author-profile-content {
  padding-left: 25px;
}

.author-box .author-profile-info .author-profile-content p {
  margin-bottom: 25px;
}

.author-box .author-profile-info .author-profile-content ul {
  padding: 0;
  margin: 0;
  margin-right: 5px;
  display: table;
  float: left;
}

.author-box .author-profile-info .author-profile-content ul li {
  padding: 0;
  margin: 0;
  margin-right: 10px;
  float: left;
}

.author-box .author-profile-info .author-profile-content ul li a {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 16px;
  display: block;
  background-color: var(--primary);
  color: #fff;
  text-align: center;
  line-height: 35px;
  border-radius: var(--border-radius-base);
}

.author-box .author-profile-info .author-profile-content ul li a:hover {
  color: #fff;
  background-color: var(--primary-hover);
}

.author-box .author-profile-info .author-profile-pic {
  width: 120px;
  height: 120px;
  overflow: hidden;
  min-width: 120px;
  border-radius: var(--border-radius-base);
}

.author-box .author-profile-info .author-profile-pic img {
  width: 100%;
}

/*# sourceMappingURL=style.css.map */
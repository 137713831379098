.main_contain {
    height: 260px;
    cursor: pointer;
    text-align: center;
    justify-content: center;
    display: flex;
}

.tree_contain {
    border-radius: 20px;
    height: 90%;
    width: 70%;
    position: absolute;
    bottom: 0;
    z-index: -1;
}

.main_contain img {
    width: 120px;
    height: 180px;
}

.main_contain p {
    font-size: 14px;
    color: gray;
    font-weight: bold;
}

.tree_detail {
    display: flex;
    justify-content: center;
}
.rsw-editor {
  border: 1px solid #5b5289;
}
.rsw-toolbar {
  background-color: #3b3363;
  border-bottom: none;
}

.rsw-btn {
  color: white;
  transition: all 0.4s;
}

.rsw-btn:hover {
  background: rgba(255, 255, 255, 0.2);
}

.rsw-separator {
  border-right: 1px solid #5b5289;
}
